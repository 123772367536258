import { all, fork } from 'redux-saga/effects'
import LayoutSaga from './layouts/saga'
//Auth
import AccountSaga from './auth/register/saga'
import AuthSaga from './auth/login/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import ProfileSaga from './auth/profile/saga'

//Project
import projectSaga from './projects/saga'
// Task
import taskSaga from './tasks/saga'
// Crypto
import cryptoSaga from './crypto/saga'
//TicketsList
import ticketsSaga from './tickets/saga'

//crm
import crmSaga from './crm/saga'
//invoice
import invoiceSaga from './invoice/saga'
//mailbox
import mailboxSaga from './mailbox/saga'

// Dashboard Analytics
import dashboardAnalyticsSaga from './dashboardAnalytics/saga'

// Dashboard CRM
import dashboardCrmSaga from './dashboardCRM/saga'

// Dashboard Ecommerce
import dashboardEcommerceSaga from './dashboardEcommerce/saga'

// Dashboard Crypto
import dashboardCryptoSaga from './dashboardCrypto/saga'

// Dashboard Project
import dashboardProjectSaga from './dashboardProject/saga'

// Dashboard NFT
import dashboardNFTSaga from './dashboardNFT/saga'

// File Manager
import fileManager from './fileManager/saga'

// To do
import todos from './todos/saga'

//Job
import jobSaga from './job/saga'

//API Key
import APIKeysaga from './apikey/saga'

export default function* rootSaga () {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),
    fork(dashboardAnalyticsSaga),
    fork(dashboardCrmSaga),
    fork(dashboardEcommerceSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardProjectSaga),
    fork(dashboardNFTSaga),
    fork(fileManager),
    fork(todos),
    fork(jobSaga),
    fork(APIKeysaga)
  ])
}
