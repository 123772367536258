import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap'

import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField'
import { Asterisk } from '../../../helpers/asterisk'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
// import { changeUserPassword} from '../../../api/company'
import { fetchCompanydetails, changeUserPassword } from '../../../store/company'
import { useDispatch } from 'react-redux'

function ChangeUserPasswordModel (props) {
  const dispatch = useDispatch()
  // const router = useHistory();
  const [new_password, setNewPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({
    new_password: '',
    confirm_password: ''
  })
  const [checkconandnewpass, setcheckconandnewpass] = useState(false)

  // password validation states
  const [hasEightCharacters, setHasEightCharacters] = useState(false)
  const [hasUppercaseLetter, setHasUppercaseLetter] = useState(false)
  const [hasLowercaseLetter, setHasLowercaseLetter] = useState(false)
  const [hasSpecialSymbol, setHasSpecialSymbol] = useState(false)
  const [hasNumbers, setHasNumbers] = useState(false)
  const [hasBothPasswordEqual, setHasBothPasswordEqual] = useState(false)
  const [companydetails, setcompanydetails] = useState({

  })

  // function validationFlase(event, values) {
  //     if (!event) {
  //         errors[values] = `This field is required!`;
  //     } else {
  //         if (event.length < 8) {
  //             errors[values] = `Must be atleast 8 characters long.`;
  //         } else {
  //             errors[values] = "";
  //         }
  //     }
  //     setErrors((old) => ({
  //         ...old,
  //     }));
  //     return { errors };
  // }

  function validationFlase (event, values) {
    if (!event) {
      errors[values] = 'This field is required!'
    } else {
      errors[values] = ''
    }
    const format = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/

    if (values === 'new_password') {
      if (event.length < 8 && confirm_password.length < 8) {
        setHasEightCharacters(false)
      } else {
        setHasEightCharacters(true)
      }

      if (/[0-9]/.test(event) || /[0-9]/.test(confirm_password)) {
        setHasNumbers(true)
      } else {
        setHasNumbers(false)
      }

      if (/[A-Z]/.test(event) || /[A-Z]/.test(confirm_password)) {
        setHasUppercaseLetter(true)
      } else {
        setHasUppercaseLetter(false)
      }

      if (/[a-z]/.test(event) || /[a-z]/.test(confirm_password)) {
        setHasLowercaseLetter(true)
      } else {
        setHasLowercaseLetter(false)
      }

      if (format.test(event) || format.test(confirm_password)) {
        setHasSpecialSymbol(true)
      } else {
        setHasSpecialSymbol(false)
      }

    } else {
      if (event.length < 8 && new_password.length < 8) {
        setHasEightCharacters(false)
      } else {
        setHasEightCharacters(true)
      }

      if (/[0-9]/.test(event) || /[0-9]/.test(new_password)) {
        setHasNumbers(true)
      } else {
        setHasNumbers(false)
      }

      if (/[A-Z]/.test(event) || /[A-Z]/.test(new_password)) {
        setHasUppercaseLetter(true)
      } else {
        setHasUppercaseLetter(false)
      }

      if (/[a-z]/.test(event) || /[a-z]/.test(new_password)) {
        setHasLowercaseLetter(true)
      } else {
        setHasLowercaseLetter(false)
      }

      if (format.test(event) || format.test(new_password)) {
        setHasSpecialSymbol(true)
      } else {
        setHasSpecialSymbol(false)
      }
    }

    if (new_password !== '' && confirm_password !== '' && new_password === confirm_password) {
      setHasBothPasswordEqual(true)
    } else {
      setHasBothPasswordEqual(false)
    }

    setErrors((old) => ({
      ...old
    }))
    return { errors }
  }

  function validation (values) {
    let isValid = true
    const formErrors = {}
    for (const [key, value] of Object.entries(values)) {

      if (!value) {
        isValid = false
        formErrors[key] = 'This field is required'
      } else {
        isValid = true
        formErrors[key] = ''
      }
    }
    setErrors(formErrors)

    return { errors, isValid }
  }

  function clearInputFields () {
    setNewPassword('')
    setConfirmPassword('')
    setHasEightCharacters(false)
    setHasUppercaseLetter(false)
    setHasLowercaseLetter(false)
    setHasSpecialSymbol(false)
    setHasNumbers(false)
    setHasBothPasswordEqual(false)
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      const validationCheck = validation({ new_password, confirm_password })

      if (validationCheck) {

        const obj = {
          user_id: companydetails.roles.length > 0 ? companydetails.roles[0].role_users[0].user.id : 0,
          new_password,
          confirm_password
        }

        // const response = await changeUserPassword(obj)
        const response = await dispatch(changeUserPassword(obj)).unwrap()
        if (response?.status?.success) {
          toast.success(response?.status?.message)
          clearInputFields()
          props.handleClose()
        } else {
          toast.error(response?.status?.message)
        }
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  useEffect(() => {

    if (props.editid) {
      fetchCompanyDetail(props.editid)
    }
  }, [])
  const fetchCompanyDetail = async (company_id) => {
    try {
      const response = await dispatch(fetchCompanydetails(company_id)).unwrap()
      if (response?.status?.success) {
        const companyDetails = response.data.companyDetails
        setcompanydetails(companyDetails)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const closeModal = () => {

    props.handleClose()
    setErrors((old) => ({
      ...old,
      password: '',
      new_password: '',
      confirm_password: ''

    }))
    clearInputFields()
  }
  return (
    <Modal isOpen={props.show}
      toggle={() => {
        closeModal()
      }}
      className="custom-modal-style"
    >
      <ModalHeader toggle={() => {
        closeModal()
      }} className="font_size_2 font_weight_3">
                Change Password
      </ModalHeader>
      <ModalBody>

        <Row form>
          <Col md={12}>
            <div>

              {/* <Card>

                                <CardBody className="p-4"> */}

              {/* <div className="text-center mt-2">
                                <h5 className="text-primary">Change Password</h5>
                            </div> */}
              <div >

                {/* {props.forgetError && props.forgetError ? (
                                    <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                                        {props.forgetError}
                                    </Alert>
                                ) : null}
                                {props.forgetSuccessMsg ? (
                                    <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                                        {props.forgetSuccessMsg}
                                    </Alert>
                                ) : null} */}

                {/* <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                > */}
                {/* <div className="mb-3">
                                        <TextField
                                            fullWidth
                                            // label="Current Password"
                                            label={<div>Current Password <Asterisk /></div>}
                                            id="outlined-size-small"
                                            size="small"
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                            onKeyUp={(event) => validationFlase(event.target.value, "password")}
                                            onBlur={(event) => validationFlase(event.target.value, "password")}
                                        />

                                        {errors.password ? (
                                            <div style={{ color: "red" }}>
                                                {errors.password}
                                            </div>
                                        ) : null}

                                    </div> */}
                <div className="mb-3">
                  <TextField
                    fullWidth
                    // label="New Password"
                    label={<div>New Password <Asterisk /></div>}
                    id="outlined-size-small"
                    size="small"
                    value={new_password}
                    onChange={(event) => {
                      setNewPassword(event.target.value.trim())
                      if (event.target.value !== confirm_password) {
                        setcheckconandnewpass(true)
                      } else {
                        setcheckconandnewpass(false)
                      }
                    }}
                    onKeyUp={(event) => validationFlase(event.target.value, 'new_password')}
                    onBlur={(event) => validationFlase(event.target.value, 'new_password')}
                  />

                  {errors.new_password ? (
                    <div style={{ color: 'red' }}>
                      {errors.new_password}
                    </div>
                  ) : null}

                </div>
                <div className="mb-3">
                  <TextField
                    fullWidth
                    // label="Confirm Password"
                    label={<div>Confirm Password <Asterisk /></div>}
                    id="outlined-size-small"
                    size="small"
                    value={confirm_password}
                    onChange={(event) => {
                      setConfirmPassword(event.target.value.trim())
                      if (new_password !== event.target.value) {
                        setcheckconandnewpass(true)
                      } else {
                        setcheckconandnewpass(false)
                      }
                    }}
                    onKeyUp={(event) => validationFlase(event.target.value, 'confirm_password')}
                    onBlur={(event) => validationFlase(event.target.value, 'confirm_password')}
                  />

                  {errors.confirm_password ? (
                    <div style={{ color: 'red' }}>
                      {errors.confirm_password}
                    </div>
                  ) :
                    checkconandnewpass && new_password !== '' && confirm_password !== '' ? (
                      <div style={{ color: 'red' }}>
                                                  New password and confirm password is not same!
                      </div>)
                    // new_password !== confirm_password) ? (
                    // <div style={{ color: "red" }}>
                    //     New password and confirm password is not same!
                    // </div>
                      : null}

                </div>
                <div style={{padding: '0 0 10px 0'}}>
                  <h6>Password must contain the following.</h6>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div style={ hasEightCharacters ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasEightCharacters ? <CheckCircleRoundedIcon /> : <CancelRoundedIcon />}</span>
                      <span style={{padding: '0 7px', fontSize: '14px', fontWeight: '600'}}>Minimum 8 character.</span>
                    </div>

                    <div style={ hasUppercaseLetter ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasUppercaseLetter ? <CheckCircleRoundedIcon /> : <CancelRoundedIcon />}</span>
                      <span style={{ padding: '0 5px', fontSize: '14px', fontWeight: '600' }}> Uppercase Letter.</span>
                    </div>

                    <div style={ hasLowercaseLetter ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasLowercaseLetter ?  <CheckCircleRoundedIcon /> : <CancelRoundedIcon />} </span>
                      <span style={{ padding: '0 5px', fontSize: '14px', fontWeight: '600' }} > Lowercase Letter. </span>
                    </div>

                    <div style={ hasNumbers ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasNumbers ? <CheckCircleRoundedIcon /> : <CancelRoundedIcon />}</span>
                      <span style={{ padding: '0 5px', fontSize: '14px', fontWeight: '600'}}> Number. </span>
                    </div>

                    <div style={ hasSpecialSymbol ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasSpecialSymbol ?  <CheckCircleRoundedIcon /> :  <CancelRoundedIcon /> } </span>
                      <span style={{ padding: '0 5px', fontSize: '14px', fontWeight: '600'}} > Special Character. </span>
                    </div>

                    <div style={ hasBothPasswordEqual ? { color: '#66dd59' } : { color: '#f55757' }}>
                      <span> {hasBothPasswordEqual ? <CheckCircleRoundedIcon /> : <CancelRoundedIcon /> } </span>
                      <span  style={{ padding: '0 5px', fontSize: '14px', fontWeight: '600' }} > Both password must be equal.  </span>
                    </div>
                  </div>
                </div>
                <div >
                  <button
                    className="btn btn-primary w-100 mx-auto button_cls btn btn-primary"
                    disabled={
                      // (password === "" || password.length < 8) ||
                      (new_password === '' || new_password.length < 8) ||
                                                (confirm_password === '' || confirm_password.length < 8) ||
                                                (new_password !== confirm_password) ||
                                                !hasEightCharacters ||
                                                !hasUppercaseLetter ||
                                                !hasLowercaseLetter ||
                                                !hasSpecialSymbol ||
                                                !hasNumbers ||
                                                !hasBothPasswordEqual
                    }
                    // className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    onClick={(event) => { handleSubmit(event) }}
                  >
                                            Submit
                  </button>
                </div>
                {/* </AvForm> */}
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal >
  )
}

export default ChangeUserPasswordModel