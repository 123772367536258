import React, { useEffect, useState } from 'react'

import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Col,
  Button
} from 'reactstrap'

import { toast } from 'react-toastify'
import MuiPhoneNumber from 'material-ui-phone-number'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Asterisk } from '../../../helpers/asterisk'
import { fetchCountries, fetchStates, fetchCities } from '../../../store/country'
import { fetchAllPlan} from '../../../store/plan'
import {
  addCompany,
  editCompany,
  fetchCompanydetails
} from '../../../store/company'
import { useDispatch } from 'react-redux'
import parsePhoneNumber from 'libphonenumber-js'

const validRegex = {
  email: /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9-.+_]+\.[a-zA-Z]{2,6}$/,
  // phone: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/,
  pin: /^[0-9]+$/,
  web: /[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?/gi

}

const AddEdit = (props) => {
  const dispatch = useDispatch()
  const [countriesLists, setCountriesLists] = useState([])
  const [statesLists, setStatesLists] = useState([])
  const [citiesLists, setCitiesLists] = useState([])
  const [plan, setplan] = useState([])
  const [disable, setDisable] = useState({isValid:false, phone:''})
  const [disable1, setDisable1] = useState({isValid:false, phone:''})
  const [startmonthoptions] = useState([
    {
      label: 'January', value:'January'

    },
    {
      label: 'February', value:'February'
    },
    {
      label: 'March', value:'March'
    },
    {
      label: 'April', value:'April'
    },
    {
      label: 'May', value:'May'
    },
    {
      label: 'June', value: 'June'
    },
    {
      label: 'July', value:'July'
    },
    {
      label: 'August', value:'August'
    },
    {
      label: 'September', value:'September'
    },
    {
      label: 'October', value:'October'
    },
    {
      label: 'November', value:'November'
    },
    {
      label: 'December', value:'December'
    }
  ])
  const [inputField, setInputField] = useState({
    //company details
    companyname: '',
    company_address: '',
    company_email: '',
    company_contact: '',
    startmonth: '',
    casual_leave: '',
    medical_leave: '',
    website: '',
    //branch details
    country: '',
    state: '',
    city: '',
    pincode: '',
    first_name:'',
    middle_name:'',
    last_name:'',

    admin_email: '',

    admin_contact: '',
    //plan
    plan: {value :'', label:''}

  })
  const [errors, setErrors] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [companydetails, setcompanydetails] = useState({

  })

  useEffect(() => {
    fetchCountrySuggestion()
    fetchAllPlans()
    if (props.editid) {
      fetchCompanyDetail(props.editid)
    }
  }, [])

  const fetchAllPlans = async (name = null) => {
    try {
      let companyPlanValue = ''
      if (name) {
        companyPlanValue = `?name=${name}`
      }
      const option = companyPlanValue
      const res = await dispatch(fetchAllPlan(option)).unwrap()
      if (res?.status?.success) {
        const arr = res.data.plans.map((it) => {
          return {
            label: it.name,
            value: it.id
          }
        })
        setplan(arr)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const inputsHandler = async (event, name) => {
    try {
      if (name === 'country') {
        if (event !== '') {
          setInputField((inputField) => {
            return {
              ...inputField,
              // [name]: { value: event.value, label: event.label },
              [name]: { value: event.value, label: event.label },
              state: '',
              city: ''
            }
          })

          //   const response = await fetchStates(event.value)
          const response = await dispatch(fetchStates(event.value)).unwrap()

          if (response?.status?.success) {
            const statesData = response && response.data.states.map(item => { return { value: item.id, label: item.name } })
            setStatesLists(statesData)
          }

        } else {
          setInputField((inputField) => {
            return {
              ...inputField,
              // [name]: { value: event.value, label: event.label },
              [name]: '',
              state: '',
              city: ''
            }
          })
        }
      } else if (name === 'state') {
        if (event !== '') {

          setInputField((inputField) => {
            return {
              ...inputField,
              [name]: { value: event.value, label: event.label },
              city: ''
            }
          })

          //   const response = await fetchCities(event.value)
          const response = await dispatch(fetchCities(event.value)).unwrap()

          if (response?.status?.success) {
            const cityData = response && response.data.cities.map(item => { return { value: item.id, label: item.name } })
            setCitiesLists(cityData)
          }
        } else {
          setInputField((inputField) => {
            return {
              ...inputField,
              [name]: '',
              city: ''
            }
          })
        }
      } else if (name === 'city') {
        if (event !== '') {
          setInputField((inputField) => {
            return {
              ...inputField,
              [name]: { value: event.value, label: event.label }

            }
          })
        } else {
          setInputField((inputField) => {
            return {
              ...inputField,
              [name]: ''

            }
          })
        }
      } else if (name === 'plan') if (event !== '') {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: { value: event.value, label: event.label }

          }
        })
      } else {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: ''

          }
        })
      }
      else if (name === 'startmonth') if (event !== '') {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: { value: event.value, label: event.label }

          }
        })
      } else {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: ''

          }
        })
      }
      else if (name === 'company_contact' || name === 'admin_contact') {
        setInputField({...inputField, [name]: event})
      } else {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: event.value ? event.value : event.target.value
          }
        })
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  async function validationFlase (event, values, country) {
    if (values === 'company_contact' || values === 'admin_contact') {
      const phoneNumberWithoutCountryCode = event.toString().replace(`+${country?.dialCode}`, '')
      const phoneNumberObj = parsePhoneNumber(phoneNumberWithoutCountryCode, country?.countryCode?.toUpperCase())
      if (values === 'company_contact') {
        setDisable({ isValid :!!phoneNumberObj?.isValid(), phone:!!phoneNumberObj?.nationalNumber})
      } else {
        setDisable1({ isValid :!!phoneNumberObj?.isValid(), phone:!!phoneNumberObj?.nationalNumber})
      }

      if (!phoneNumberWithoutCountryCode) {
        errors[values] = 'This field is required.'
      } else if (!phoneNumberObj?.isValid()) {
        errors[values] = 'Please enter a valid phone number.'
      } else {
        errors[values] = ''
      }
    } else if (values === 'company_email' || values === 'admin_email') {
      if (!event) {
        errors[values] = 'This field is required .'
      } else if (event && !validRegex.email.test(event)) {
        errors[values] = 'Invalid email id.'
      } else {
        errors[values] = ''
      }

    } else if (values === 'pincode') {
      if (!validRegex.pin.test(event)) {
        errors[values] = 'Pin must be only number.'
      } else {
        errors[values] = ''
      }
    } else if (values === 'website') {
      if (!event.match(validRegex.web)) {
        errors[values] = 'Enter a valid URL.'
      } else {
        console.log('no')
        errors[values] = ''
      }
    } else {
      errors[values] = ''
    }
    setErrors((old) => ({
      ...old
    }))
    return { errors }
  }

  const fetchCountrySuggestion = async () => {
    try {
      const response = await dispatch(fetchCountries()).unwrap()
      if (response?.status?.success) {
        const countryData = response && response.data.countries.map(item => { return { value: item.id, label: item.name } })
        setCountriesLists(countryData)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const createCompany = async () => {
    try {
      const formData = {
        name: inputField.companyname,
        address: inputField.company_address,
        primary_email: inputField.company_email,
        primary_phone: inputField.company_contact,
        account_year_start_month: inputField.startmonth.label,
        medical_leave: parseInt(inputField.medical_leave),
        casual_leave: parseInt(inputField.casual_leave),
        website: inputField.website,

        country: inputField.country.value,
        state: inputField.state.value,
        city: inputField.city.value,
        pin: parseInt(inputField.pincode),
        plan_id: inputField.plan.value,
        first_name: inputField.first_name,
        middle_name: inputField.middle_name,
        last_name: inputField.last_name,
        admin_email: inputField.admin_email,
        admin_phone: inputField.admin_contact
      }
      const response = await dispatch(addCompany(formData)).unwrap()
      if (response?.status?.success) {
        toast.success(response?.status?.message)
        props.handleClose()
        props.fetchCompanies()
      } else {
        toast.error(response?.status?.message)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const updateCompany = async () => {
    try {
      const arr_brk = companydetails.roles.length > 0 ? companydetails.roles[0] : ''

      const user_id = arr_brk && arr_brk.role_users.length > 0 ? arr_brk.role_users[0].user_id : ''

      const formData = {
        name: inputField.companyname,
        address: inputField.company_address,
        primary_email: inputField.company_email,
        primary_phone: inputField.company_contact,
        account_year_start_month: inputField.startmonth.label,
        medical_leave: parseInt(inputField.medical_leave),
        casual_leave: parseInt(inputField.casual_leave),
        website: inputField.website,

        country: inputField.country.value,
        state: inputField.state.value,
        city: inputField.city.value,
        pin: parseInt(inputField.pincode),
        plan_id: inputField.plan.value,
        company_id:companydetails.id,
        company_branch_id:companydetails.company_branches.length > 0 ? companydetails.company_branches[0].id : 0,
        user_id:user_id !== '' ? user_id : 0,
        first_name: inputField.first_name,
        middle_name: inputField.middle_name,
        last_name: inputField.last_name,
        admin_email: inputField.admin_email,
        admin_phone: inputField.admin_contact
      }
      const response = await dispatch(editCompany(formData)).unwrap()
      if (response?.status?.success) {
        toast.success(response?.status?.message)
        props.fetchCompanies()
        props.handleClose(false)
      } else {
        toast.error(response?.status?.message)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }

  }

  const fetchCompanyDetail = async (company_id) => {
    try {
      const response = await dispatch(fetchCompanydetails(company_id)).unwrap()
      if (response?.status?.success) {
        const companyDetails = response.data.companyDetails
        if (response.data.companyDetails.company_branches.length > 0) {
          if (response.data.companyDetails.company_branches[0].country_id != null) {
            const response1 = await dispatch(fetchStates(response.data.companyDetails.company_branches[0].country_id.id)).unwrap()
            if (response1?.status?.success) {
              const statesData = response1 && response1.data.states.map(item => { return { value: item.id, label: item.name } })
              setStatesLists(statesData)
            }
          }
          if (response.data.companyDetails.company_branches[0].state_id != null) {
            const response2 = await dispatch(fetchCities(response.data.companyDetails.company_branches[0].state_id.id)).unwrap()
            if (response2?.status?.success) {
              const cityData = response2 && response2.data.cities.map(item => { return { value: item.id, label: item.name } })
              setCitiesLists(cityData)
            }

          }
        }
        setcompanydetails(companyDetails)
        const countrydata = companyDetails.company_branches.length > 0 && companyDetails.company_branches[0].country_id
        const statedata = companyDetails.company_branches.length > 0 && companyDetails.company_branches[0].state_id
        const citydata = companyDetails.company_branches.length > 0 && companyDetails.company_branches[0].city_id
        const plandata = companyDetails.company_plans[0].plan
        const arr_brk = companyDetails.roles.length > 0 ? companyDetails.roles[0] : ''
        const admin_details = arr_brk && arr_brk.role_users.length > 0 ? arr_brk.role_users[0].user : ''
        if (companyDetails['primary_phone']) {
          setDisable({...disable, isValid:true})
        }
        if (admin_details.contact_number) {
          setDisable1({...disable1, isValid:true})
        }
        const name = admin_details ? admin_details.name : ''

        const arr = name.replace('Super Admin- ', '')
        const splitName = arr.split(' ')
        const first_name = splitName.slice(0, 1) ? splitName.slice(0, 1).toString() : ''
        const mid_name = splitName.slice(1, -1) ? splitName.slice(1, -1).join(' ') : ''
        const last_name = splitName.slice(-1) ? splitName.slice(-1).toString() : ''

        setInputField({
          companyname: companyDetails['name'],
          company_address: companyDetails['address'],
          company_email: companyDetails['primary_email'],
          company_contact: companyDetails['primary_phone'],
          startmonth: {value:companyDetails['account_year_start_month'], label:companyDetails['account_year_start_month']},
          casual_leave: Math.trunc(companyDetails['casual_leave']),
          medical_leave: Math.trunc(companyDetails['medical_leave']),
          website: companyDetails['website'],
          country: countrydata ? { value: countrydata['id'], label: countrydata['name'] } : [],
          city: citydata ? { value: citydata['id'], label: citydata['name'] } : [],
          state:statedata ? { value: statedata['id'], label: statedata['name'] } : [],
          pincode: companyDetails.company_branches.length > 0 ? companyDetails.company_branches[0]['pin'] : '',

          // branch_email:companyDetails.company_branches.length>0? companyDetails.company_branches[0]["email"]:"",

          // branch_contact: companyDetails.company_branches.length>0? companyDetails.company_branches[0]["ph_no"]:"",
          // branch_address: companyDetails.company_branches.length>0? companyDetails.company_branches[0]["address"]:"",
          plan: plandata ? { value: plandata['id'], label: plandata['name'] } : [],
          admin_contact:admin_details ? admin_details.contact_number : '',
          admin_email:admin_details ? admin_details.email : '',
          first_name,
          middle_name:mid_name,
          last_name

        })
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  return (
    <React.Fragment>

      <Offcanvas
        isOpen={props.isRight}
        toggle={props.toggle}
        direction="end"
        onExit={() => props.handleClose()}
        className="offcanvas-end border-0">
        <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={props.toggle}>
          <span className="m-0 me-2 text-white">{props.editid ? 'Update' : 'Add'} Company</span>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">

          {/* <Col xl="12" className="pb-2 ps-2 mt-2 pe-2">
                            <Controller
                                name='name'
                                control={control}
                                defaultValue={companyDetails.id ? defaultValues.name : ''}
                                required
                                autoFocus
                                render={({ field: {value, ...field} }) => {
                                    return (
                                        <>
                                            <TextField
                                                {...field}
                                                label={
                                                    <div>
                                                      Company Name
                                                    </div>
                                                  }
                                                defaultValue = {companyDetails.id ? defaultValues.name : ''}
                                                // value={ defaultValues.name }
                                                onChange= {e=>{defaultValues.name = e.target.value}}
                                                type="text"
                                                size="small"
                                                fullWidth
                                            />
                                            {!!errors.name ? <span style={{fontSize:'10px', color:'red'}}>{errors?.name?.message}</span> : <></> }
                                        </>
                                    )
                                }}
                            />
                        </Col> */}
          <Col xl="12" className="pb-2 ps-2 mt-3 pe-2 font_weight_2">
            <strong style={{ fontSize: '15px' }}>Company Details </strong>
          </Col>
          <Col xl="12" className="pb-2 ps-2 mt-2 pe-2">
            <TextField
              inputProps={{
                autocomplete: 'designation',
                form: {
                  autocomplete: 'off'
                }
              }}
              id="outlined-size-small"
              name="name"
              label={
                <div>
                                    Organization Name <Asterisk />
                </div>
              }
              // id="outlined-size-small"
              size="small"

              value={inputField.companyname}
              onChange={(event) => inputsHandler(event, 'companyname')}
              onKeyUp={(event) => validationFlase(event.target.value, 'companyname', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'companyname', '')
              }
              className="form-control"
              //placeholder="Enter email"
              type="text"
              //required
            />
            {errors.companyname ? (
              <div style={{ color: 'red' }}>
                {errors.companyname}
              </div>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            {/* <TextField
              name="contact"
              label={
                <div>
                                    Contact Number <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              value={inputField.company_contact}
              onChange={(event) => inputsHandler(event, 'company_contact')}
              onKeyUp={(event) => validationFlase(event.target.value, 'company_contact')
              }
              onBlur={(event) => validationFlase(event.target.value, 'company_contact')
              }

              fullWidth
            /> */}

            {/* {errors.company_contact ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.company_contact}</span>
            ) : null} */}
            <MuiPhoneNumber
              countryCodeEditable= {false}
              // defaultCountry={JSON.parse(localStorage.getItem('authUser')).country.iso2.toLowerCase()}
              defaultCountry={'in'}
              variant='outlined'
              id="outlined-size-small"
              size='small'
              value={inputField.company_contact}
              label={<div> Contact Number <Asterisk /> </div>}
              onChange={(event, country) => {
                inputsHandler(event,  'company_contact')
                validationFlase(
                  event,
                  'company_contact',
                  country
                )
              }}
              fullWidth
            />
            {errors.company_contact ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.company_contact}</span>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="email"
              label={
                <div>
                                    Email ID <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="email"
              value={inputField.company_email}
              onChange={(event) => inputsHandler(event, 'company_email')}
              onKeyUp={(event) => validationFlase(event.target.value, 'company_email', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'company_email', '')
              }

              fullWidth
            />

            {errors.company_email ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.company_email}</span>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="website"
              label={
                <div>
                                    Website <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"

              value={inputField.website}
              onChange={(event) => {
                inputsHandler(event, 'website')
                validationFlase(event.target.value, 'website')
              }
              }
              onKeyUp={(event) => validationFlase(event.target.value, 'website', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'website', '')
              }

              fullWidth
            />

            {errors.website ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.website}</span>
            ) : null}

          </Col>
          {/* <Col xl="12" className="pb-2 ps-2 mt-3 pe-2 font_weight_2">
                        <strong style={{ fontSize: "15px" }}>Company Branch Details </strong>
                    </Col> */}

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">

            <Autocomplete

              disablePortal
              size="small"
              id="country"
              value={inputField.country}
              options={countriesLists}
              renderInput={(params) => <TextField
                inputProps={{
                  autocomplete: 'country',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                {...params} label={<div>Country <Asterisk /></div>} />}

              onChange={(event, newValue) => {
                if (newValue != null) {
                  inputsHandler(newValue, 'country')
                  validationFlase(newValue, 'country')
                } else {
                  inputsHandler('', 'country')
                  validationFlase('', 'country')
                }
              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'country', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'country', '')
              }

            />
            {errors.country ? (
              <div style={{ color: 'red' }}>
                {errors.country}
              </div>
            ) : null}
          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">

            <Autocomplete
              disablePortal
              size="small"
              id="state"
              value={inputField.state}
              options={statesLists}
              renderInput={(params) => <TextField
                autoComplete='off'
                {...params} label={<div>State <Asterisk /></div>} />}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  inputsHandler(newValue, 'state')
                  validationFlase(newValue, 'state')
                } else {
                  inputsHandler('', 'state')
                  validationFlase('', 'state')
                }
              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'state', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'state', '')
              }
            />
            {errors.state ? (
              <div style={{ color: 'red' }}>
                {errors.state}
              </div>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <Autocomplete
              disablePortal
              size="small"
              id="city"
              value={inputField.city}
              options={citiesLists}
              renderInput={(params) => <TextField {...params} label={<div>City <Asterisk /></div>} />}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  inputsHandler(newValue, 'city')
                  validationFlase(newValue, 'city')
                } else {
                  inputsHandler('', 'city')
                  validationFlase('', 'city')
                }
              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'city', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'city', '')
              }
            />
            {errors.city ? (
              <div style={{ color: 'red' }}>
                {errors.city}
              </div>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="zipcode"
              label={
                <div>
                                    Pin Code <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="text"
              value={inputField.pincode}
              onChange={(event) => inputsHandler(event, 'pincode')}
              onKeyUp={(event) => validationFlase(event.target.value, 'pincode', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'pincode', '')
              }
              inputProps={{
                autocomplete: 'pin',
                form: {
                  autocomplete: 'off'
                }
              }}

              fullWidth
            />

            {errors.pincode ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.pincode}</span>
            ) :

              null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="address"
              label={
                <div>
                                    Address <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="email"
              value={inputField.company_address}
              onChange={(event) => inputsHandler(event, 'company_address')}
              onKeyUp={(event) => validationFlase(event.target.value, 'company_address', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'company_address', '')
              }
              inputProps={{
                autocomplete: 'pin',
                form: {
                  autocomplete: 'off'
                }
              }}

              fullWidth
            />

            {errors.company_address ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.company_address}</span>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="casual"
              label={
                <div>
                                    Casual Leave <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              value={inputField.casual_leave}
              onChange={(event) => inputsHandler(event, 'casual_leave')}
              onKeyUp={(event) => validationFlase(event.target.value, 'casual_leave', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'casual_leave', '')
              }

              fullWidth
            />

            {errors.casual_leave ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.casual_leave}</span>
            ) : null}

          </Col> <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="casual"
              label={
                <div>
                                    Medical Leave <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              value={inputField.medical_leave}
              onChange={(event) => inputsHandler(event, 'medical_leave')}
              onKeyUp={(event) => validationFlase(event.target.value, 'medical_leave', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'medical_leave', '')
              }

              fullWidth
            />

            {errors.medical_leave ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.medical_leave}</span>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <Autocomplete

              disablePortal
              size="small"
              id="startmonth"
              value={inputField.startmonth}
              options={startmonthoptions}
              renderInput={(params) => <TextField {...params} label={<div>Financial Year Starting Month <Asterisk /></div>} />}

              onChange={(event, newValue) => {
                if (newValue != null) {
                  inputsHandler(newValue, 'startmonth')
                  validationFlase(newValue, 'startmonth')
                } else {
                  inputsHandler('', 'startmonth')
                  validationFlase('', 'startmonth')
                }
              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'startmonth', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'startmonth', '')
              }

            />

            {errors.startmonth ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.startmonth}</span>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 ps-2 mt-3 pe-2 font_weight_2">
            <strong style={{ fontSize: '15px' }}>Admin Details </strong>
          </Col>
          <Col xl="12" className="pb-2 ps-2 mt-2 pe-2">
            <TextField
              inputProps={{
                autocomplete: 'designation',
                form: {
                  autocomplete: 'off'
                }
              }}
              id="outlined-size-small"
              name="name"
              label={
                <div>
                                    First Name <Asterisk />
                </div>
              }
              // id="outlined-size-small"
              size="small"

              value={inputField.first_name}
              onChange={(event) => inputsHandler(event, 'first_name')}
              onKeyUp={(event) => validationFlase(event.target.value, 'first_name', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'first_name', '')
              }
              className="form-control"
              //placeholder="Enter email"
              type="text"
              //required
            />
            {errors.first_name ? (
              <div style={{ color: 'red' }}>
                {errors.first_name}
              </div>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 ps-2 mt-2 pe-2">
            <TextField
              inputProps={{
                autocomplete: 'designation',
                form: {
                  autocomplete: 'off'
                }
              }}
              id="outlined-size-small"
              name="name"
              label={
                <div>
                                    Middle Name
                </div>
              }
              // id="outlined-size-small"
              size="small"

              value={inputField.middle_name}
              onChange={(event) => inputsHandler(event, 'middle_name')}
              // onKeyUp={(event) =>
              //     validationFlase(event.target.value, "mid_name")
              // }
              // onBlur={(event) =>
              //     validationFlase(event.target.value, "mid_name")
              // }
              className="form-control"
              //placeholder="Enter email"
              type="text"
              //required
            />
            {/* {errors.mid_name ? (
                            <div style={{ color: "red" }}>
                                {errors.mid_name}
                            </div>
                        ) : null} */}

          </Col>
          <Col xl="12" className="pb-2 ps-2 mt-2 pe-2">
            <TextField
              inputProps={{
                autocomplete: 'designation',
                form: {
                  autocomplete: 'off'
                }
              }}
              id="outlined-size-small"
              name="name"
              label={
                <div>
                                    Last Name <Asterisk />
                </div>
              }
              // id="outlined-size-small"
              size="small"

              value={inputField.last_name}
              onChange={(event) => inputsHandler(event, 'last_name')}
              onKeyUp={(event) => validationFlase(event.target.value, 'last_name', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'last_name', '')
              }
              className="form-control"
              //placeholder="Enter email"
              type="text"
              //required
            />
            {errors.last_name ? (
              <div style={{ color: 'red' }}>
                {errors.last_name}
              </div>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="email"
              label={
                <div>
                                    Email ID <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="email"
              value={inputField.admin_email}
              onChange={(event) => inputsHandler(event, 'admin_email')}
              onKeyUp={(event) => validationFlase(event.target.value, 'admin_email', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'admin_email', '')
              }

              fullWidth
            />

            {errors.admin_email ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.admin_email}</span>
            ) : null}

          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            {/* <TextField
              name="email"
              label={
                <div>
                                    Contact Number <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="email"
              value={inputField.admin_contact}
              onChange={(event) => inputsHandler(event, 'admin_contact')}
              onKeyUp={(event) => validationFlase(event.target.value, 'admin_contact', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'admin_contact', '')
              }

              fullWidth
            /> */}
            <MuiPhoneNumber
              countryCodeEditable= {false}
              // defaultCountry={JSON.parse(localStorage.getItem('authUser')).country.iso2.toLowerCase()}
              defaultCountry={'in'}
              variant='outlined'
              id="outlined-size-small"
              size='small'
              value={inputField.admin_contact}
              label={<div> Contact Number <Asterisk /> </div>}
              onChange={(event, country) => {
                inputsHandler(event,  'admin_contact')
                validationFlase(
                  event,
                  'admin_contact',
                  country
                )
              }}
              fullWidth
            />

            {errors.admin_contact ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.admin_contact}</span>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 ps-2 mt-3 pe-2 font_weight_2">
            <strong style={{ fontSize: '15px' }}>{props.editid ? 'Selected Plan' : 'Select Plan '}</strong>
          </Col>
          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">

            <Autocomplete

              disablePortal
              size="small"
              id="plan"
              value={inputField.plan}
              options={plan}
              renderInput={(params) => <TextField {...params} label={<div>Plan {props.editid ? null : <Asterisk />}</div>} />}
              disabled={props.editid}
              readOnly={props.editid}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  inputsHandler(newValue, 'plan')
                  validationFlase(newValue, 'plan', '')
                } else {
                  inputsHandler('', 'plan')
                  validationFlase('', 'plan', '')
                }
              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'plan', '')
              }
              onBlur={(event) => validationFlase(event.target.value, 'plan', '')
              }

            />
            {errors.plan ? (
              <div style={{ color: 'red' }}>
                {errors.plan}
              </div>
            ) : null}

          </Col>

          <div className=" text-center pt-1 pb-2">
            <Button
              className="btn  header_3 button_cls my-auto px-4 border btn-info"
              type="submit"

              disabled={

                !inputField.companyname ||
                                // !inputField.country ||
                                // !inputField.state ||
                                // !inputField.city ||
                                !inputField.pincode ||
                                !validRegex.pin.test(inputField.pincode) ||
                                !inputField.company_address ||
                                !inputField.company_email ||
                                !validRegex.email.test(inputField.company_email) ||
                                 !disable.isValid ||
                                 !disable1.isValid ||
                                !inputField.casual_leave ||
                                !inputField.medical_leave ||
                                !inputField.website ||
                                !inputField.website.match(validRegex.web) ||

                                // !inputField.branch_email ||
                                // !validRegex.email.test(inputField.branch_email) ||
                                // !inputField.branch_address ||
                                // !inputField.branch_contact ||
                                // !validRegex.phone.test(inputField.branch_contact) ||
                                (props.editid ? false : !inputField.plan) ||

                                !inputField.first_name ||
                                !inputField.last_name ||
                                !inputField.admin_email

              }
              onClick={() => {
                if (props?.editid) {
                  updateCompany()
                } else {
                  createCompany()
                }
              }}
            >
              {props.editid ? 'Update' : 'Add'}
            </Button>
          </div>

        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}
export default AddEdit