import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withRouter from '../Components/Common/withRouter'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

//redux
import { useSelector } from 'react-redux'

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState('')
  const {
    layoutType,
    layoutModeType
  } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType
  }))

  /*
    layout settings
    */
  /*
    call dark/light mode
    */

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })

  function scrollNavigation () {
    const scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType} />
        <Sidebar
          layoutType={layoutType}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      {/* <RightSidebar /> */}
    </React.Fragment>

  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default withRouter(Layout)
