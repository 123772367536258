// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS'
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR'
export const GET_TASK_LIST = 'GET_TASK_LIST'

/**
* Add TASK
*/
export const ADD_NEW_TASK = 'ADD_NEW_TASK'
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL'

/**
 * Edit TASK
 */
export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'

/**
 * Delete TASK
 */
export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL'