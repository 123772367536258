// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS'
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR'
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const DELETE_PROJECT_LIST = 'DELETE_PROJECT_LIST'
export const DELETE_PROJECT_LIST_SUCCESS = 'DELETE_PROJECT_LIST_SUCCESS'
export const DELETE_PROJECT_LIST_FAIL = 'DELETE_PROJECT_LIST_FAIL'
export const ADD_PROJECT_LIST = 'ADD_PROJECT_LIST'
export const ADD_PROJECT_LIST_SUCCESS = 'ADD_PROJECT_LIST_SUCCESS'
export const ADD_PROJECT_LIST_FAIL = 'ADD_PROJECT_LIST_FAIL'
export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST'
export const UPDATE_PROJECT_LIST_SUCCESS = 'UPDATE_PROJECT_LIST_SUCCESS'
export const UPDATE_PROJECT_LIST_FAIL = 'UPDATE_PROJECT_LIST_FAIL'

