import React, { useEffect, useState } from 'react'

import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Col,
  Input,
  Label,
  Row,
  Button
} from 'reactstrap'

import { toast } from 'react-toastify'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Asterisk } from '../../../src/helpers/asterisk'
import {fetchAllMenu, updateCompanyPermission, addUpdatePlan} from '../../store/plan'
import { useDispatch } from 'react-redux'
const validRegex = {
  email: /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9-.+_]+\.[a-zA-Z]{2,6}$/,
  // phone: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/,
  pin: /^[0-9]+$/,
  web: /[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?/gi

}

const AddEdit = (props) => {
  const dispatch = useDispatch()
  //const [, setStatesLists] = useState([])
  const [allChecked, setallChecked] = useState(false)
  const validityOptions = [
    {
      label: '1 year', value: '1'
    },
    {
      label: '2 year', value: '2'
    },
    {
      label: '3 year', value: '3'
    },
    {
      label: '4 year', value: '4'
    },
    {
      label: '5 year', value: '5'
    }
  ]
  const [planModules, setplanModules] = useState([
    {
      title: 'Employees', selected:false
    },
    {
      title: 'Projects', selected:false
    },
    {
      title: 'Clients', selected:false
    },
    {
      title: 'Leave', selected:false
    }
  ])
  const [inputField, setInputField] = useState({
    name: '',
    validity: '',
    price:''

  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    // console.log("heyy",props.editid)
    fetchModules(props?.editid ? props?.editid : 0)
  }, [])

  const inputsHandler = async (event, name) => {
    try {
      if (name === 'validity') {
        if (event !== '') {
          setInputField((inputField) => {
            return {
              ...inputField,
              // [name]: { value: event.value, label: event.label },
              [name]: { value: event.value, label: event.label }

            }
          })

        } else {
          setInputField((inputField) => {
            return {
              ...inputField,
              [name]: ''

            }
          })
        }
      } else {
        setInputField((inputField) => {
          return {
            ...inputField,
            [name]: event.value ? event.value : event.target.value
          }
        })
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  // function validationFlase(event, values) {
  //     console.log(values)
  //     console.log(validRegex.email.test(event))
  //     if (!event) {
  //         errors[values] = "This field is required!";
  //     } else {
  //         errors[values] = "";
  //     }
  //     setErrors((old) => ({
  //         ...old,
  //     }));
  //     return { errors };
  // }

  function validationFlase (event, values) {
    if (!event) {
      errors[values] = 'This field is required!'
    } else if (values === 'company_email' || values === 'admin_email') {
      if (!validRegex.email.test(event)) {
        errors[values] = 'Invalid email id!'
      } else {
        errors[values] = ''
      }
    } else if (values === 'company_contact' || values === 'admin_contact') {
      if (!validRegex.phone.test(event)) {
        errors[values] = 'Enter a valid contact number!'
      } else if (event.length > 10) {
        errors[values] = 'Contact number must be 10 digits!'
      } else {
        errors[values] = ''
      }
    } else if (values === 'pincode') {
      if (!validRegex.pin.test(event)) {
        errors[values] = 'Pin must be only number!'
      } else {
        errors[values] = ''
      }
    } else if (values === 'website') {
      if (!event.match(validRegex.web)) {
        // console.log("hii")
        errors[values] = 'Enter a valid URL!'
      } else {
        // console.log("no")
        errors[values] = ''
      }
    } else {
      errors[values] = ''
    }
    setErrors((old) => ({
      ...old
    }))
    // console.log("erry", errors)
    return { errors }
  }

  function formatMenus (menus) {
    for (const menu of menus) {
      menu['title'] = (menu.title[0].toUpperCase() + menu.title.slice(1))
    }
    return menus
  }

  const fetchModules = async (id) => {
    try {
      //const response = await fetchAllMenus(id)
      const response = await dispatch(fetchAllMenu(id)).unwrap()
      if (response?.status?.success) {
        formatMenus(response.data.menus)
        setplanModules(response.data.menus)
        const arr = response.data.menus.filter(it => it.selected)
        if (arr.length === response.data.menus.length) {
          setallChecked(true)
        }
        const validity = validityOptions.filter(it => it.label === response.data.plans[0].validity)
        setInputField({
          name:response.data.plans.length > 0 ? response.data.plans[0].name : '',
          price:response.data.plans.length > 0 ? response.data.plans[0].price : '',
          validity:{label:validity[0].label, value:validity[0].value}
        })
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const editCompanyPermission = async () => {
    try {
      await dispatch(updateCompanyPermission({plan_id:props.editid ? props.editid : 0})).unwrap()
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }
  const addEditPlan = async () => {
    try {
      const arr = planModules.filter(it => it.selected)

      const obj = {
        plan_id:props.editid ? props.editid : 0,
        plan_name:inputField.name,
        modules:props.editid ? planModules : arr,
        validity:inputField.validity.label,
        price:parseInt(inputField.price)

      }

      // const response = await addUpdatePlan(obj)
      const response = await dispatch(addUpdatePlan(obj)).unwrap()
      if (response?.status?.success) {
        toast.success(response?.status?.message)
        props.handleClose(false)
        if (props.editid) {
          editCompanyPermission()
        }
      } else {
        toast.error(response?.status?.message)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }

  }

  // const updateCompany = () => {
  //     console.log()
  //     let arr=planModules.filter(it=>it.selected);
  //     console.log(arr)

  //     let obj = {
  //         plan_name:inputField.name,
  //         modules:arr,
  //        validity:inputField.validity.label,
  //        price:parseInt(inputField.price)

  //     }
  //     console.log(obj)
  //     EditPlan(obj).then(response => {
  //         console.log('148=>',response)
  //         if (response.statusCode === 201) {
  //             toast.error(response.message)
  //         } else {
  //             toast.success(response.message)
  //             props.handleClose(false)
  //         }
  //     }).catch(err => {
  //         console.log('150=>', err)
  //     })

  // }

  // const submitForm = (formData) => {
  //     console.log('63=>', formData);
  //     if (companyDetails.id)
  //         updateCompany(formData)
  //     else
  //         createCompany(formData)
  // }

  // useEffect(() => {
  //     Object.keys(defaultValues).forEach(item => {
  //         setValue(item, defaultValues[item])
  //     })
  //     if (companyDetails.id) {
  //         fetchStateSuggestion(companyDetails["company_branches.country.id"])
  //         fetchCitySuggestion(companyDetails["company_branches.state.id"])
  //     }
  // }, [companyDetails.id])

  const checkModules = () => {
    const  values = [...planModules]

    const arr = values.filter(it => it.selected)
    if (arr.length > 0) {
      return true
    }
  }

  return (
    <React.Fragment>

      <Offcanvas
        isOpen={props.isRight}
        toggle={props.toggle}
        direction="end"
        onExit={() => props.handleClose()}
        className="offcanvas-end border-0">
        <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={props.toggle}>
          <span className="m-0 me-2 text-white">{props.editid ? 'Update' : 'Add'} Plan</span>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0 scroller">

          <Col xl="12" className="pb-2 ps-2 mt-3 pe-2">
            <TextField
              inputProps={{
                autocomplete: 'designation',
                form: {
                  autocomplete: 'off'
                }
              }}
              id="outlined-size-small"
              name="name"
              label={
                <div>
                                    Plan Name <Asterisk />
                </div>
              }
              // id="outlined-size-small"
              size="small"

              value={inputField.name}
              onChange={(event) => inputsHandler(event, 'name')}
              onKeyUp={(event) => validationFlase(event.target.value, 'name')
              }
              onBlur={(event) => validationFlase(event.target.value, 'name')
              }
              className="form-control"
              //placeholder="Enter email"
              type="text"
              //required
            />
            {errors.name ? (
              <div style={{ color: 'red' }}>
                {errors.name}
              </div>
            ) : null}

          </Col>

          <Col xl="12" className="pb-2 ps-2 pe-2 mt-3 font_weight_2">
            <strong style={{marginRight:'4px'}}>Available Modules to Select</strong><Asterisk />
          </Col>
          <Row>
            <div style={{ margin: '12px' }}>
              <Label
                className="form-check-label"
                htmlFor="customRadioInline1"
                style={{ marginLeft: '-2px'
                }}
              >
                              All Modules
              </Label>
              <Input
                type="checkbox"
                id="customCheckInline1"
                name="customCheckInline1"
                className="form-check-input"
                style={{ marginLeft: '8px'
                }}
                checked={allChecked}
                onChange={(event) => {
                  if (event.target.checked) {
                    const values = [...planModules]
                    values.map((item) => {
                      item.selected = true
                      return item
                    })
                    setplanModules(values)
                    setallChecked(!allChecked)
                  } else {
                    const values = [...planModules]
                    values.map((item) => {
                      item.selected = false
                      return item
                    })
                    setplanModules(values)
                    setallChecked(!allChecked)
                  }
                  console.log(planModules)
                }}
              />
            </div>

            {/* <PermissionList allPermissionSelected={allPermissionSelected} setAllChecked={setAllChecked} allChecked={allChecked} setMenus={setMenus} menu={menu} selectedMenus={selectedMenus} setAllSelection={setAllPermissionSelected}/> */}
          </Row>
          <Row>
            { planModules.map((item, index) => {
              return (
                <Col xl="4" className="pb-2 mt-2 ps-2 " key={index}>
                  {/* <h5 className="my-0 text-primary"> */}
                  <div style={{ marginLeft: '15px', marginRight: '10px'}}>
                    {item.title}
                    <Input
                      type="checkbox"
                      id="customCheckInline2"
                      name="customCheckInline2"
                      className="form-check-input"
                      style={{ float: 'right' }}
                      checked={item.selected ? item.selected : false}
                      onChange={(e) => {
                      // console.log(e)
                        if (e.target.checked) {
                          const values = [...planModules]
                          values[index]['selected'] = !item.selected
                          setplanModules(values)
                          const arr = values.filter(it => it.selected === true)
                          // console.log(arr.length)
                          if (arr.length === values.length) {
                            setallChecked(true)
                          }

                        } else {
                          const values = [...planModules]
                          values[index]['selected'] = !item.selected
                          setplanModules(values)
                          setallChecked(false)

                        }
                      // console.log(planModules)
                      }}
                    />
                  </div>
                  {/* </h5> */}

                </Col>)
            })
            }

            {/* <PermissionList allPermissionSelected={allPermissionSelected} setAllChecked={setAllChecked} allChecked={allChecked} setMenus={setMenus} menu={menu} selectedMenus={selectedMenus} setAllSelection={setAllPermissionSelected}/> */}
          </Row>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">

            <Autocomplete

              disablePortal
              size="small"
              id="validity"
              value={inputField.validity}
              options={validityOptions}
              renderInput={(params) => <TextField
                inputProps={{
                  autocomplete: 'validity',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                {...params} label={<div>Validity <Asterisk /></div>} />}

              onChange={(event, newValue) => {
                // console.log(newValue);
                if (newValue != null) {
                  inputsHandler(newValue, 'validity')
                  validationFlase(newValue, 'validity')
                } else {
                  inputsHandler('', 'validity')
                  validationFlase('', 'validity')
                }

              }}
              onKeyUp={(event) => validationFlase(event.target.value, 'validity')
              }
              onBlur={(event) => validationFlase(event.target.value, 'validity')
              }

            />
            {errors.validity ? (
              <div style={{ color: 'red' }}>
                {errors.validity}
              </div>
            ) : null}
          </Col>

          <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
            <TextField
              name="zipcode"
              label={
                <div>
                                    Price <Asterisk />
                </div>
              }
              id="outlined-size-small"
              size="small"
              type="text"
              value={inputField.price}
              onChange={(event) => inputsHandler(event, 'price')}
              onKeyUp={(event) => validationFlase(event.target.value, 'price')
              }
              onBlur={(event) => validationFlase(event.target.value, 'price')
              }
              inputProps={{
                autocomplete: 'pin',
                form: {
                  autocomplete: 'off'
                }
              }}

              fullWidth
            />

            {errors.price ? (
              <span style={{ fontSize: '12px', color: 'red' }}>  {errors.price}</span>
            ) :

              null}

          </Col>

          <div className=" text-center pt-1 pb-2">
            <Button
              className="btn  header_3 button_cls my-auto px-4 border btn-info"
              type="submit"

              disabled={

                !inputField.name ||
                                 !checkModules() ||
                                !inputField.validity ||
                                !inputField.price

              }
              onClick={() => {
                // if (props.editid) {
                //     updateCompany()
                // } else {
                //     createCompany()
                // }
                addEditPlan()
              }}
            >
              {props.editid ? 'Update' : 'Add'}
            </Button>
          </div>

        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}
export default AddEdit