import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {get} from '../../helpers/api_helper'

export const dashboardData = createAsyncThunk('company-count', async () => {
  const response = await get('/fetch-company-count-status-wise')
  return response
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {},
  extraReducers () {}
})

export default dashboardSlice.reducer