import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, Button, Offcanvas, Spinner, Card, CardBody } from 'reactstrap'
import BreadCrumb from '../../../src/Components/Common/BreadCrumb'
import { useParams } from 'react-router-dom'
import _debounce from 'lodash/debounce'

import { toast } from 'react-toastify'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import AddEdit from './AddEditPlan'
import { fetchAllPlan, deleteAdminPlan} from '../../store/plan'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useDispatch } from 'react-redux'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const CompaniesList = () => {
  const params = useParams()
  const dispatch = useDispatch()

  document.title = params.type === 'ACT' ? 'TeamTrace | Admin Active Companies' : 'TeamTrace | Admin InActive Companies'

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState('')
  const [dynamic_description, setdynamic_description] = useState('')
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState('')
  const [isRight, setIsRight] = useState(false)
  const [plan, setplan] = useState([])
  const [delid, setdelid] = useState('')
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)

  const toggleRightSidebar = () => {
    setIsRight(!isRight)
  }

  function goUpdatePage (id) {
    //history.push(`/edit_skill/${id}`);
    setId(id)
    setIsRight(!isRight)
  }

  const fetchAllPlans = async (name = null) => {
    try {
      setLoading(true)
      let companyPlanValue = ''
      if (name) {
        companyPlanValue = `?name=${name}`
      }
      const option = companyPlanValue
      const response = await dispatch(fetchAllPlan(option)).unwrap()
      if (response?.status?.success) {
        setplan(response.data.plans)
        setCount(response.data.count)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
    setLoading(false)
  }
  function searchData (searchValue) {
    try {

      fetchAllPlans(searchValue)
    } catch (ex) {
      toast.error(ex.message)
    }
  }
  const debounceSearchTask = useCallback(_debounce(searchData, 500), [])
  const columplan = [
    {
      dataField: 'company_name',
      text: 'Plan Name',
      //   sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row.name}

            </div>
          </div>
        )
      }

    },
    // {
    //     dataField: "module",
    //     text: "Module",
    //   //   sort: true,
    //     formatter: (cell, row) => {
    //       return (
    //         <div className="table_row">
    //           <div>
    //             {/* {row.name} */}
    //          Employee
    //           </div>
    //         </div>
    //       );
    //     },

    //   },

    {
      dataField: 'company_contactNumber',
      text: 'Validity',
      //   sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row['validity']}
              {/* 1 yr */}
            </div>
          </div>
        )
      }
    },

    {
      dataField: 'number_of_guards',
      text: 'Price',
      //   sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row['price']}
            </div>
          </div>
        )
      }
    },

    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <div>
            {/* <i className="bx bx-trash-alt pe-3 delete icon font-size-16" role="button"></i> */}
            <i
              className="bx bx-pencil pe-3 ion-button edit icon font-size-16"
              role="button"
              onClick={() => {
                goUpdatePage(row.id)
              }}
            ></i>

            <i className="ri-delete-bin-line" role="button"
              onClick={() => {
                setdelid(row.id)
                setconfirm_alert(true)
              }}
            ></i>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    fetchAllPlans()
    setSearch('')
  }, [])

  const handleClose = (val) => {
    setIsRight(val)
    fetchAllPlans()

  }

  async function deleteFunc () {
    try {
      //const res = await delPlan(delid)
      const res = await dispatch(deleteAdminPlan(delid)).unwrap()
      if (res?.status?.success) {
        setsuccess_dlg(true)
        toast.success(res?.status?.message)
        await fetchAllPlans()
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
          onCancel={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure you want to delete the data?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"

          onConfirm={() => {
            deleteFunc()
            setconfirm_alert(false)
            // setsuccess_dlg(true);
            setdynamic_title('Deleted')
            setdynamic_description('Your data has been deleted.')
          }}
          onCancel={() => setconfirm_alert(false)}
        >
            You won&apos;t be able to revert this!
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <Container fluid>
          <div>
            <Row>
              <Col
                // md={8}
                // xl={8}
              >

                {/* <BreadCrumb title="Plans" /> */}
                <h4 className='mb-0'>Plans</h4>

              </Col>
              <Col
                // md={4}
                // xl={4}
                className="button-width d-flex justify-content-end"
              >

                <div className="d-flex">
                  <TextField
                    className='ms-2'
                    placeholder="Search By plan name"
                    id="outlined-size-small"
                    size="small"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                      debounceSearchTask(e.target.value)
                    }}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start"><SearchIcon />
                        </InputAdornment>
                    }}
                  />
                  <Button
                    className="button_cls" style={{marginLeft:'20px'}}
                    onClick={() => {
                      toggleRightSidebar()
                      setId('')
                    }}
                  >
                    <div className="d-flex" style={{ gap: '5px' }}>
                      <i className="mdi mdi-plus fa-lg"></i>
                      <span>Add Plan</span>
                    </div>
                  </Button>
                </div>

              </Col>
            </Row>
          </div>
          <Row className="mt-2">
            <Col xl={12}>
              {/* <Card>
                <CardBody >
                  <ToolkitProvider
                    keyField="id"
                    data={plan || []}
                    columns={columplan || []}
                    bootstrap4
                    search
                  >
                    {(toolkitProps) => (
                       <React.Fragment> */}

              <BootstrapTable
                classes="react-bootstrap-table background-table"
                // pagination={paginationFactory({
                //   page: page,
                //   sizePerPage: limit,
                //   totalSize: count,
                //   showTotal: true,
                //   alwaysShowAllBtns: true,
                //   sizePerPageList: [10, 20, 50],
                //   // onPageChange: (e) => {
                //   //     pageChange(e, limit);
                //   // },
                //   // onSizePerPageChange: (e) => {
                //   //     sizeChange(e);
                //   // },
                // })}
                // onTableChange={onTableChange}
                keyField="id"
                pagination={paginationFactory({sizePerPageList: [10, 20, 50],
                  showTotal: true,
                  totalSize: count,
                  alwaysShowAllBtns: true})}
                data={plan}
                columns={columplan}
                bordered={false}
                striped={false}
                headerClasses={'table_head_background_color table_background'}
                rowClasses={
                  'font-family font_size_4 color_main_text table_border_bottom'
                }
                responsive
                noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
                // {...toolkitProps.baseProps}
              />
              {/* </React.Fragment>
                    )}

                  </ToolkitProvider>
                </CardBody>

              </Card> */}
            </Col>
            {/* {loading && <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div>} */}
            {/* {!plan?.length && !loading && <div className="mb-3"><NoDataFound /></div>} */}
          </Row>

        </Container>

      </div>

      {isRight ?
        <AddEdit
          isRight={isRight}
          handleClose={handleClose}
          editid={id}
          toggle={toggleRightSidebar}

        /> : null}
    </React.Fragment>
  )
}

export default CompaniesList
