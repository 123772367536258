import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { Row, Col, Button, Container } from 'reactstrap'
import { TextField } from '@mui/material'
import { toast } from 'react-toastify'
// import { fetchChronTime, chronTimeCreate } from '../../api/company'
import { fetchChronTime, chronTimeCreate} from '../../store/company'
import { useDispatch } from 'react-redux'

const Settings = () => {
  const dispatch = useDispatch()
  const [chronTime, setChronTime] = useState('04:00')
  document.title = 'TeamTrace | Admin Dashboard'

  async function fetchChronJobTime () {
    try {
      const res = await dispatch(fetchChronTime()).unwrap()
      if (res?.status?.success) {
        setChronTime(res.data[0].chron_time)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const onHandleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(chronTimeCreate({ chron_time: chronTime })).unwrap()
      if (res?.status?.success) {
        toast.success(res?.status?.message)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  useEffect(() => {
    fetchChronJobTime()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Settings"/>
          <Row pt={1} px={0}>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-2 h-100">
                <div className="card-body">
                  <div className="float-start ">
                    <span className="text-info">
                      {/* <i className='bx bx-building-house bx-lg'></i> */}
                    </span>
                  </div>
                  <div>
                    {/* <p className="text-muted mb-0 card_heading"> */}
                    <Row>
                      <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
                        <TextField
                          id="time"
                          size="small"
                          label="Cron Job Timing"
                          type="time"
                          value={ chronTime }
                          onChange={ (event) => setChronTime(event.target.value) }
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            step: 300 // 5 min
                          }}
                          fullWidth
                        />
                      </Col>
                    </Row>
                    {/* </p> */}
                    <div className="text-center pt-3">
                      <Button
                        className="btn  header_3 button_cls my-auto px-4 border btn-info"
                        type="submit"
                        onClick={(event) => { onHandleSubmit(event) }}>
                                                Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="float-start">
                    <span className="inactive_icon">
                      {/* <i className='bx bx-building-house bx-lg'></i> */}
                    </span>
                  </div>
                  <div>
                    <p className="text-muted mb-0 card_heading">
                      {/* <Row>
                                                <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
                                                    <TextField
                                                        id="time"
                                                        size="small"
                                                        label="Cron Job Timing"
                                                        type="time"
                                                        defaultValue="07:30"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </Row> */}
                    </p>
                    <h4 className="mb-1 mt-1">
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="float-start">
                    <span className="inactive_icon">
                      {/* <i className='bx bx-building-house bx-lg'></i> */}
                    </span>
                  </div>
                  <div>
                    <p className="text-muted mb-0 card_heading">
                      {/* <Row>
                                                <Col xl="12" className="pb-2 mt-2 ps-2 pe-2">
                                                    <TextField
                                                        id="time"
                                                        size="small"
                                                        label="Cron Job Timing"
                                                        type="time"
                                                        defaultValue="07:30"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </Row> */}
                    </p>
                    <h4 className="mb-1 mt-1">
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
