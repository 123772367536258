import { get, create, update, put } from './api_helper'

import * as url from './url_helper'

// const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user')
  if (user) return JSON.parse(user)
  return null
}

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
export const postFakeRegister = data => create(url.POST_FAKE_REGISTER, data)

// Login Method
export const postFakeLogin = data => create(url.POST_FAKE_LOGIN, data)

// postForgetPwd
export const postFakeForgetPwd = data => create(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
export const postJwtProfile = data => create(url.POST_EDIT_JWT_PROFILE, data)

export const postFakeProfile = (data) => update(`${url.POST_EDIT_PROFILE  }/${  data.idx}`, data)

// Register Method
export const postJwtRegister = (url, data) => {
  return create(url, data)
    .catch(err => {
      let message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found'
          break
        case 500:
          message = 'Sorry! something went wrong, please contact our support team'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = err[1]
          break
        }
      }
      throw message
    })
}

// Login Method
export const postJwtLogin = data => create(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
export const postJwtForgetPwd = data => create(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => create(url.SOCIAL_LOGIN, data)

// Calendar
// get Events
export const getEvents = () => get(url.GET_EVENTS)

// get Events
export const getCategories = () => get(url.GET_CATEGORIES)

// get Upcomming Events
export const getUpCommingEvent = () => get(url.GET_UPCOMMINGEVENT)

// add Events
export const addNewEvent = event => create(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event => delete (url.DELETE_EVENT, { headers: { event } })

// Chat
// get Contact
export const getDirectContact = () => get(url.GET_DIRECT_CONTACT)

// get Messages
export const getMessages = roomId => get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// add Message
export const addMessage = message => create(url.ADD_MESSAGE, message)

// add Message
export const deleteMessage = message => delete (url.DELETE_MESSAGE, { headers: { message } })

// get Channels
export const getChannels = () => get(url.GET_CHANNELS)

// MailBox
//get Mail
export const getMailDetails = () => get(url.GET_MAIL_DETAILS)

// delete Mail
export const deleteMail = forId => delete (url.DELETE_MAIL, { headers: { forId } })

// Ecommerce
// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// delete Product
export const deleteProducts = product => delete (`${url.DELETE_PRODUCT  }/${  product}`)

// add Products
export const addNewProduct = product => create(url.ADD_NEW_PRODUCT, product)

// update Products
export const updateProduct = product => update(`${url.UPDATE_PRODUCT  }/${  product._id}`, product)

// get Orders
export const getOrders = () => get(url.GET_ORDERS)

// add Order
export const addNewOrder = order => create(url.ADD_NEW_ORDER, order)

// update Order
export const updateOrder = order => update(`${url.UPDATE_ORDER  }/${  order._id}`, order)

// delete Order
export const deleteOrder = order => delete (`${url.DELETE_ORDER  }/${  order}`)

// get Customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add Customers
export const addNewCustomer = customer => create(url.ADD_NEW_CUSTOMER, customer)

// update Customers
export const updateCustomer = customer => update(`${url.UPDATE_CUSTOMER  }/${  customer._id}`, customer)

// delete Customers
export const deleteCustomer = customer => delete (`${url.DELETE_CUSTOMER  }/${  customer}`)

// get Sellers
export const getSellers = () => get(url.GET_SELLERS)

// Project
// get Project list
export const getProjectList = () => get(url.GET_PROJECT_LIST)

// Tasks
// get Task
export const getTaskList = () => get(url.GET_TASK_LIST)

// add Task
export const addNewTask = task => create(url.ADD_NEW_TASK, task)

// update Task
export const updateTask = task => update(`${url.UPDATE_TASK  }/${  task._id}`, task)

// delete Task
export const deleteTask = task => delete (`${url.DELETE_TASK  }/${  task}`)

// CRM
// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// add Contact
export const addNewContact = contact => create(url.ADD_NEW_CONTACT, contact)

// update Contact
export const updateContact = contact => update(`${url.UPDATE_CONTACT  }/${  contact._id}`, contact)

// delete Contact
export const deleteContact = contact => delete (`${url.DELETE_CONTACT  }/${  contact}`)

// get Companies
export const getCompanies = () => get(url.GET_COMPANIES)

// add Companies
export const addNewCompanies = company => create(url.ADD_NEW_COMPANIES, company)

// update Companies
export const updateCompanies = company => update(`${url.UPDATE_COMPANIES  }/${  company._id}`, company)

// delete Companies
export const deleteCompanies = company => delete (`${url.DELETE_COMPANIES  }/${  company}`)

// get Deals
export const getDeals = () => get(url.GET_DEALS)

// get Leads
export const getLeads = () => get(url.GET_LEADS)

// add Lead
export const addNewLead = lead => create(url.ADD_NEW_LEAD, lead)

// update Lead
export const updateLead = lead => update(`${url.UPDATE_LEAD  }/${  lead._id}`, lead)

// delete Lead
export const deleteLead = lead => delete (`${url.DELETE_LEAD  }/${  lead}`)

// Crypto
// Transation
export const getTransationList = () => get(url.GET_TRANSACTION_LIST)

// Order List
export const getOrderList = () => get(url.GET_ORDRER_LIST)

// Invoice
//get Invoice
export const getInvoices = () => get(url.GET_INVOICES)

// add Invoice
export const addNewInvoice = invoice => create(url.ADD_NEW_INVOICE, invoice)

// update Invoice
export const updateInvoice = invoice => update(`${url.UPDATE_INVOICE  }/${  invoice._id}`, invoice)

// delete Invoice
export const deleteInvoice = invoice => delete (`${url.DELETE_INVOICE  }/${  invoice}`)

// Support Tickets
// Tickets
export const getTicketsList = () => get(url.GET_TICKETS_LIST)

// add Tickets
export const addNewTicket = ticket => create(url.ADD_NEW_TICKET, ticket)

// update Tickets
export const updateTicket = ticket => update(`${url.UPDATE_TICKET  }/${  ticket._id}`, ticket)

// delete Tickets
export const deleteTicket = ticket => delete (`${url.DELETE_TICKET  }/${  ticket}`)

// Dashboard Analytics

// Sessions by Countries
export const getAllData = () => get(url.GET_ALL_DATA)
export const getHalfYearlyData = () => get(url.GET_HALFYEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

// Audiences Metrics
export const getAllAudiencesMetricsData = () => get(url.GET_ALLAUDIENCESMETRICS_DATA)
export const getMonthlyAudiencesMetricsData = () => get(url.GET_MONTHLYAUDIENCESMETRICS_DATA)
export const getHalfYearlyAudiencesMetricsData = () => get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA)
export const getYearlyAudiencesMetricsData = () => get(url.GET_YEARLYAUDIENCESMETRICS_DATA)

// Users by Device
export const getTodayDeviceData = () => get(url.GET_TODAYDEVICE_DATA)
export const getLastWeekDeviceData = () => get(url.GET_LASTWEEKDEVICE_DATA)
export const getLastMonthDeviceData = () => get(url.GET_LASTMONTHDEVICE_DATA)
export const getCurrentYearDeviceData = () => get(url.GET_CURRENTYEARDEVICE_DATA)

// Audiences Sessions by Country
export const getTodaySessionData = () => get(url.GET_TODAYSESSION_DATA)
export const getLastWeekSessionData = () => get(url.GET_LASTWEEKSESSION_DATA)
export const getLastMonthSessionData = () => get(url.GET_LASTMONTHSESSION_DATA)
export const getCurrentYearSessionData = () => get(url.GET_CURRENTYEARSESSION_DATA)

// Dashboard CRM

// Balance Overview
export const getTodayBalanceData = () => get(url.GET_TODAYBALANCE_DATA)
export const getLastWeekBalanceData = () => get(url.GET_LASTWEEKBALANCE_DATA)
export const getLastMonthBalanceData = () => get(url.GET_LASTMONTHBALANCE_DATA)
export const getCurrentYearBalanceData = () => get(url.GET_CURRENTYEARBALANCE_DATA)

// Dial Type
export const getTodayDealData = () => get(url.GET_TODAYDEAL_DATA)
export const getWeeklyDealData = () => get(url.GET_WEEKLYDEAL_DATA)
export const getMonthlyDealData = () => get(url.GET_MONTHLYDEAL_DATA)
export const getYearlyDealData = () => get(url.GET_YEARLYDEAL_DATA)

// Sales Forecast
export const getOctSalesData = () => get(url.GET_OCTSALES_DATA)
export const getNovSalesData = () => get(url.GET_NOVSALES_DATA)
export const getDecSalesData = () => get(url.GET_DECSALES_DATA)
export const getJanSalesData = () => get(url.GET_JANSALES_DATA)

// Dashboard Ecommerce
// Revenue
export const getAllRevenueData = () => get(url.GET_ALLREVENUE_DATA)
export const getMonthRevenueData = () => get(url.GET_MONTHREVENUE_DATA)
export const getHalfYearRevenueData = () => get(url.GET_HALFYEARREVENUE_DATA)
export const getYearRevenueData = () => get(url.GET_YEARREVENUE_DATA)

// Dashboard Crypto
// Portfolio
export const getBtcPortfolioData = () => get(url.GET_BTCPORTFOLIO_DATA)
export const getUsdPortfolioData = () => get(url.GET_USDPORTFOLIO_DATA)
export const getEuroPortfolioData = () => get(url.GET_EUROPORTFOLIO_DATA)

// Market Graph
export const getAllMarketData = () => get(url.GET_ALLMARKETDATA_DATA)
export const getYearMarketData = () => get(url.GET_YEARMARKET_DATA)
export const getMonthMarketData = () => get(url.GET_MONTHMARKET_DATA)
export const getWeekMarketData = () => get(url.GET_WEEKMARKET_DATA)
export const getHourMarketData = () => get(url.GET_HOURMARKET_DATA)

// Dashboard Project
// Project Overview
export const getAllProjectData = () => get(url.GET_ALLPROJECT_DATA)
export const getMonthProjectData = () => get(url.GET_MONTHPROJECT_DATA)
export const gethalfYearProjectData = () => get(url.GET_HALFYEARPROJECT_DATA)
export const getYearProjectData = () => get(url.GET_YEARPROJECT_DATA)

// Project Status
export const getAllProjectStatusData = () => get(url.GET_ALLPROJECTSTATUS_DATA)
export const getWeekProjectStatusData = () => get(url.GET_WEEKPROJECTSTATUS_DATA)
export const getMonthProjectStatusData = () => get(url.GET_MONTHPROJECTSTATUS_DATA)
export const getQuarterProjectStatusData = () => get(url.GET_QUARTERPROJECTSTATUS_DATA)

// Dashboard NFT
// Marketplace
export const getAllMarketplaceData = () => get(url.GET_ALLMARKETPLACE_DATA)
export const getMonthMarketplaceData = () => get(url.GET_MONTHMARKETPLACE_DATA)
export const gethalfYearMarketplaceData = () => get(url.GET_HALFYEARMARKETPLACE_DATA)
export const getYearMarketplaceData = () => get(url.GET_YEARMARKETPLACE_DATA)

// Project
export const addProjectList = (project) => create(url.ADD_NEW_PROJECT, project)
export const updateProjectList = (project) => put(url.UPDATE_PROJECT, project)
export const deleteProjectList = (project) => delete (url.DELETE_PROJECT, { headers: { project } })

// Pages > Team
export const getTeamData = (team) => get(url.GET_TEAMDATA, team)
export const deleteTeamData = (team) => delete (url.DELETE_TEAMDATA, { headers: { team } })
export const addTeamData = (team) => create(url.ADD_NEW_TEAMDATA, team)
export const updateTeamData = (team) => put(url.UPDATE_TEAMDATA, team)

// File Manager

// Folder
export const getFolders = (folder) => get(url.GET_FOLDERS, folder)
export const deleteFolder = (folder) => delete (url.DELETE_FOLDER, { headers: { folder } })
export const addNewFolder = (folder) => create(url.ADD_NEW_FOLDER, folder)
export const updateFolder = (folder) => put(url.UPDATE_FOLDER, folder)

// File
export const getFiles = (file) => get(url.GET_FILES, file)
export const deleteFile = (file) => delete (url.DELETE_FILE, { headers: { file } })
export const addNewFile = (file) => create(url.ADD_NEW_FILE, file)
export const updateFile = (file) => put(url.UPDATE_FILE, file)

// To Do
export const getTodos = (todo) => get(url.GET_TODOS, todo)
export const deleteTodo = (todo) => delete (url.DELETE_TODO, { headers: { todo } })
export const addNewTodo = (todo) => create(url.ADD_NEW_TODO, todo)
export const updateTodo = (todo) => put(url.UPDATE_TODO, todo)

// To do Project
export const getProjects = (project) => get(url.GET_PROJECTS, project)
export const addNewProject = (project) => create(url.ADD_NEW_TODO_PROJECT, project)

//Job Application
export const getJobApplicationList = () => get(url.GET_APPLICATION_LIST)

//API Key
export const getAPIKey = () => get(url.GET_API_KEY)
