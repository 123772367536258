import PropTypes from 'prop-types'
import React from 'react'
import { Navigate } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux'

import withRouter from '../../Components/Common/withRouter'

const Logout = () => {

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout
  }))

  if (isUserLogout) {
    return <Navigate to="/login" />
  }

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object
}

export default withRouter(Logout)