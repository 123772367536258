import React, {} from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup
} from 'reactstrap'

function MoveToActiveDialog (props) {

  const { updateCompanyStatus, toggle, modal, companyName, status } = props

  const onUpdate = () => {
    updateCompanyStatus()
    toggle()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} style={{alignItems:'normal', borderBottom:'1px solid #ddd'}}><h4>Move to {status ? status : 'Active'}
        </h4></ModalHeader>
        <ModalBody>
          <h5>Are you sure to move <b>{companyName ? companyName.toUpperCase() : ''}</b> to {status ? status : 'Active'}?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onUpdate()}>
            Move to {status ? status : 'Active'}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default MoveToActiveDialog