import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const BreadCrumb = ({ title, pageTitle, goBack }) => {
  const navigate = useNavigate()

  // const BreadCrumb = ({ title, pageTitle }) => {
  //     return (
  //         <React.Fragment>
  //             <Row>
  //                 <Col xl={9} md={9}>
  //                     <div className="page-title-box d-sm-flex align-items-center justify-content-between">
  //                      <h4 className="mb-sm-0"><span><i className="mdi mdi-arrow-left text-primary me-1 left-icon"></i></span>{title}</h4>

  const allowNavigatePagesTitles = ['Company Details']

  const goToPage = (pageTitle) => {
    switch (pageTitle) {
    case 'Company Details': {
      navigate(goBack)
      break
    }
    default: break
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={9} md={9}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">
              {allowNavigatePagesTitles.includes(title) &&
                <span>
                  <i className="mdi mdi-arrow-left text-primary me-1 left-icon" onClick={() => goToPage(title)}></i>
                </span>
              }
              {title}
            </h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                {/* <li className="breadcrumb-item active">{title}</li> */}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BreadCrumb
