import React, { useState, useEffect } from 'react'
import { Card, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import {dashboardData} from '../../store/dashboardData'
import { useDispatch } from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import Autocomplete from '@mui/material/Autocomplete'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Asterisk } from '../../helpers/asterisk'
import { TextField } from '@mui/material'

const DashboardEcommerce = () => {
  const dispatch = useDispatch()
  document.title = 'TeamTrace | Admin Dashboard'
  const [noOfActive, setNoOfActive] = useState(null)
  const [noOfInactive, setNoOfInactive] = useState(null)

  const fetchCompanies = async () => {
    try {
      const response = await dispatch(dashboardData()).unwrap()
      if (response?.status?.success) {
        setNoOfActive(response.data.activeCompaniesCount)
        setNoOfInactive(response.data.inactiveCompaniesCount)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard"/>
          <Row pt={1} px={0}>
            {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="float-start">
                    <span className="text-success">
                      <i className='bx bx-building-house bx-lg'></i>
                    </span>
                  </div>
                  <div className="float-end">
                    <p  className="text-muted mb-0 card_heading">
                      On-Boarding Companies
                    </p>
                    <h4 className="mb-1 mt-1">
                      <span data-plugin="counterup" className="float-end fw_4">
                        {noOfOnboarding}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="float-start ">
                    <span className="text-info">
                      <i className='bx bx-building-house bx-lg'></i>
                    </span>
                  </div>
                  <div className="float-end">
                    <p className="text-muted mb-0 card_heading">
                      Active Companies
                    </p>
                    <h4 className="mb-1 mt-1">
                      <span data-plugin="counterup" className="float-end fw_4">
                        {noOfActive ? noOfActive : 0}

                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="float-start">
                    <span className="inactive_icon">
                      <i className='bx bx-building-house bx-lg'></i>
                    </span>
                  </div>
                  <div className="float-end">
                    <p className="text-muted mb-0 card_heading">
                      InActive Companies
                    </p>
                    <h4 className="mb-1 mt-1">
                      <span data-plugin="counterup" className="float-end fw_4">
                        {noOfInactive ? noOfInactive : 0}

                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='d-flex flex-wrap'>
              <Card style={{minWidth: '200px', marginBottom:'120px'}}>
                <div className='d-flex justify-content-between align-items-center' style={{marginTop:'20px'}}>
                  <p style={{marginLeft:'10px'}}>User Logged In</p>
                  <p style={{marginRight:'10px', width:'144px'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date range"
                        // format="DD-MM-YYYY"
                        inputFormat="DD/MM/YYYY"
                        className="date_height"
                        name="from_date"
                        // value={dateRangeData.startDate}
                        // onChange={(date) => changeDateSelection('startDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                      />
                    </LocalizationProvider>
                  </p>
                </div>
                <div className='col-md-6' style={{background:'white', marginTop:'50px'}}>
                  <ReactApexChart
                    options={company.options}
                    series={company.series}
                    type="bar"
                    width="500"
                  />
                </div>
              </Card>
              <Card style={{minWidth: '400px', marginBottom:'120px', marginLeft:'10px', padding:'20px'}}>
                <div className='d-flex justify-content-between align-items-center' style={{marginTop:'20px'}}>
                  <p style={{marginLeft:'10px'}}>User Logged in(Company)</p>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} label="select company" />}
                  />
                  <div style={{marginRight:'10px', width:'144px'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date range"
                        format="DD-MM-YYYY"
                        inputFormat="dd/MM/yyyy"
                        className="date_height"
                        name="from_date"
                        // value={dateRangeData.startDate}
                        // onChange={(date) => changeDateSelection('startDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className='col-md-6' style={{background:'white', marginTop:'50px'}}>
                  <ReactApexChart
                    options={dateWise.options}
                    series={dateWise.series}
                    type="bar"
                    width="500"
                  />
                </div>
              </Card>

            </div> */}
            {/* <Col xl={4} lg={4} md={4}>
              <Card md={6}>
                <div className="float-start p-2">
                  <span className="text-success">
                    <i className='bx bx-building-house bx-md'></i>
                  </span>
                </div>
                <div className="float-end">
                  <p className="text-muted mb-0">
                    InActive Companies
                  </p>
                  <h4 className="mb-1 mt-1">
                    <span className="float-end fw_4">
                      {noOfInactive}
                    </span>
                  </h4>
                </div>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardEcommerce
