import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {get} from '../../helpers/api_helper'

export const fetchCountries = createAsyncThunk('countries', async () => {
  const response = await get('/fetch-countries')
  return response
})
export const fetchStates = createAsyncThunk('states', async (country_id) => {
  const response = await get(`/fetch-states?country_id=${country_id}`)
  return response
})
export const fetchCities = createAsyncThunk('cities', async (state_id) => {
  const response = await get(`/fetch-cities?state_id=${state_id}`)
  return response
})

const countrySlice = createSlice({
  name: 'activity',
  initialState: {},
  reducers: {},
  extraReducers () {}
})

export default countrySlice.reducer