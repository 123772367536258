import axios from 'axios'
import { Redirect } from 'react-router-dom'
//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

export const axiosApi = axios.create({
  baseURL: API_URL
})

axiosApi.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error?.response?.data?.statusCode === 422) {
    localStorage.clear()
    window.location = '/login'
  } else {
    return Promise.reject(error?.response?.data)
  }
})

export async function get (url, config = {}) {
  if (localStorage.getItem('token')) {

    const data1 = JSON.parse(localStorage.getItem('token'))
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${data1}`

  }
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export function post (url, data, config = {}) {

  if (localStorage.getItem('token')) {

    const data1 = JSON.parse(localStorage.getItem('token'))
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${data1}`

  }

  if (config.headers) {
    axiosApi.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  }

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put (url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del (url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export async function getLoggedinUser () {
  const user = sessionStorage.getItem('authUser')
  if (!user) {
    return null
  } else {
    return JSON.parse(user)
  }
}

export async function  setAuthorization (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${  token}`
}

// content type
export async function init () {
  const token = JSON.parse(sessionStorage.getItem('authUser')) ? JSON.parse(sessionStorage.getItem('authUser')).data.token : null
  if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${  token}`
}
// init()
export async function  update () {
  // return axios.patch();
}

export async function  create () {
  // return axios.patch();
}

