import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {post, get} from '../../helpers/api_helper'
export const fetchCompanyDetails = createAsyncThunk('fetch-company-details', async (data) => {
  return await post('/fetch-company-login-details', data)
})
export const getEachCompanyLoginDetails = createAsyncThunk('get-each-company-login', async (data) => {
  const response = await post('/get-each-company-login-details', data)
  return response
})
export const getCompanyList = createAsyncThunk('get-company-list', async () => {
  const response = await get('/get-company-list')
  return response
})

const activitySlice = createSlice({
  name: 'activity',
  initialState: {},
  reducers: {},
  extraReducers () {}
})

export default activitySlice.reducer