import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'
import { toast } from 'react-toastify'
//redux
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { Asterisk } from '../../helpers/asterisk'

// import logoLight from "../../assets/images/logo-light.png";
import logoLight from '../../assets/images/teamtrace-logo.svg'

import withRouter from '../../Components/Common/withRouter'
import TextField from '@mui/material/TextField'
import { post } from '../../helpers/api_helper'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

const Login = () => {
  const navigate = useNavigate()
  const { user } = useSelector(state => ({
    user: state.Account.user
  }))
  const [email, setEmail] = useState('superadmin@gmail.com')
  const [password, setPassword] = useState('code1234')
  const [errorsdata, setErrorsdata] = useState({
    email: '',
    password: ''

  })
  const [showPasswordlogin, setshowPasswordlogin] = useState(false)

  const [, setUserLogin] = useState([])

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password
      })
    }
  }, [user])

  function validation (values) {
    values['email'] = email
    values['password'] = password
    setErrorsdata(values)
    let isValid = true
    const formErrors = {}

    for (const [key, value] of Object.entries(errorsdata)) {

      if (!value) {
        // isValid = false;
        formErrors[key] = 'This field is required!'
      } else {
        // isValid = true;
        formErrors[key] = ''
      }
    }
    if (email !== '' && password !== '') {
      isValid = true

    } else {
      isValid = false

    }

    setErrorsdata(formErrors)
    return { errorsdata, isValid }
  }
  function validationFlase (event, values) {
    if (!event) {
      errorsdata[values] = 'This field is required!'
    } else {
      errorsdata[values] = ''
    }
    setErrorsdata((old) => ({
      ...old
    }))
    return { errorsdata }
  }
  const handleValidSubmit = async (event) => {
    try {
      event.preventDefault()
      const validationCheck = validation(errorsdata)
      if (validationCheck.isValid) {
        const values = {
          email,
          password
        }
        const response = await post('/admin/login', values)
        if (response?.status?.success) {
          sessionStorage.setItem('authUser', JSON.stringify(response))
          localStorage.setItem('token', JSON.stringify(response.data.token))
          navigate('/dashboard')
        } else {
          toast.error(response?.status?.message)
        }
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const { error } = useSelector(state => ({
    error: state.Login.error
  }))

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleClickShowPasswordlogin = () => {

    setshowPasswordlogin(!showPasswordlogin)

  }

  document.title = 'TeamTrace | Login'
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-1 text-white-50">
                  <div>
                    <Link to="/login" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="50" />
                    </Link>
                  </div>
                  {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-2 mb-0">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back!</h5>
                      <p className="text-muted">Sign in to continue to Super Admin Panel</p>
                    </div>
                    {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                    <div className="p-2 mt-4">
                      {/* <Form
                                                onSubmit={(e) => {
                                                    // e.preventDefault();
                                                    // validation.handleSubmit();
                                                    // return false;
                                                    handleValidSubmit()
                                                }}
                                                action="#"> */}
                      <form onSubmit={handleValidSubmit}>
                        <div className="mb-3">
                          <TextField
                            name="email"
                            label={
                              <div>
                                                                Email <Asterisk />
                              </div>
                            }
                            id="outlined-size-small"
                            size="small"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onKeyUp={(event) => validationFlase(event.target.value, 'email')
                            }
                            onBlur={(event) => validationFlase(event.target.value, 'email')
                            }
                            // required

                            fullWidth
                            inputProps={{
                              autocomplete: 'email',
                              form: {
                                autocomplete: 'off'
                              }
                            }}
                          />

                          {errorsdata.email ? (
                            <span style={{ fontSize: '12px', color: 'red' }}>  {errorsdata.email}</span>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <TextField
                            //    inputProps={{
                            //     autocomplete: 'pass',
                            //     form: {
                            //         autocomplete: 'off',
                            //     },
                            // }}
                            autoComplete='off'
                            name="password"
                            label={
                              <div>
                                                                Password <Asterisk />
                              </div>
                            }
                            id="outlined-size-small"
                            size="small"
                            // type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)
                            }
                            onKeyUp={(event) => validationFlase(event.target.value, 'password')
                            }
                            onBlur={(event) => validationFlase(event.target.value, 'password')
                            }
                            fullWidth
                            type={showPasswordlogin ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPasswordlogin(
                                      'password'
                                    )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPasswordlogin ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                              // autocomplete: "new-password",
                              // form: {
                              //   autocomplete: "off",
                              // },
                            }}

                          />
                          {errorsdata.password ? (
                            <span style={{ fontSize: '12px', color: 'red' }}> {errorsdata.password}</span>
                          ) : null}
                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon">
                            {/* <i className="ri-eye-fill align-middle"></i> */}
                          </button>
                        </div>

                        {/* <div className="form-check input_box">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div> */}

                        <div className="mt-4">
                          <Button color="success" className="btn btn-success w-100"
                            // onClick={handleValidSubmit}
                            type="submit">Log In</Button>
                        </div>
                      </form>

                      {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <FacebookLogin
                                                            appId={facebook.APP_ID}
                                                            autoLoad={false}
                                                            callback={facebookResponse}
                                                            render={renderProps => (
                                                                <Button color="primary"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-facebook-fill fs-16" />
                                                                </Button>
                                                            )}
                                                        />
                                                        <GoogleLogin
                                                            clientId={
                                                                google.CLIENT_ID ? google.CLIENT_ID : ""
                                                            }
                                                            render={renderProps => (
                                                                <Button color="danger"
                                                                    to="#"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-google-fill fs-16" />
                                                                </Button>
                                                            )}
                                                            onSuccess={googleResponse}
                                                            onFailure={(error) => {
                                                            }}
                                                        />
                                                        <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                        <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                    </div>
                                                </div> */}
                      {/* </Form> */}
                    </div>
                  </CardBody>
                </Card>

                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}
                <div className="mt-3 text-center">
                  <p className="mb-0 text-muted">© {new Date().getFullYear()} TeamTrace</p>
                </div>

              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)