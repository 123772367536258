import React, { useEffect, useState} from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import moment from 'moment'
import Autocomplete from '@mui/material/Autocomplete'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import {
  fetchCompanyDetails,
  getEachCompanyLoginDetails,
  getCompanyList
} from '../../store/report'

const Reports = () => {

  const dispatch = useDispatch()
  document.title = 'TeamTrace | Admin Dashboard'
  const [changeAssignDate, setChangeAssignDate] = useState(new Date())
  const [duration, setDuration] = useState('month')
  const [companyListDropdown, setCompanyListDropdown] = useState([])
  const [companyName, setCompanyName] = useState({})
  const [categoriesDataForBarChart, setCategoriesDataForBarChart] = useState([])
  const [categoriesDataForBarChart1, setCategoriesDataForBarChart1] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const chartOptions1 = {
    chart: {
      type: 'bar'
    },
    toolbar: {
      show: false
    },
    title: {
      text: '',
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      }
    },
    colors:['#0000'],
    noData: {
      text: 'No Data Found',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [],
      title: {
        text: ''
      }
    },
    yaxis: {
      title: {
        text: ''
      }
    },
    fill: {
      opacity: 1
    }
  }
  const [company, setCompany] = useState({
    series: [
      {
        name: 'company',
        data: []
      }
    ],
    options: chartOptions1
  })
  const chartOptions = {
    chart: {
      type: 'bar'
    },
    title: {
      text: '',
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      }
    },
    colors:['#0000'],
    noData: {
      text: 'Plese select company to view data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [],
      title: {
        text: ''
      }
    },
    yaxis: {
      title: {
        text: ''
      }
    },
    fill: {
      opacity: 1
    },
    toolbar: {
      show: false // Set to false to hide the toolbar/menu icon
    }
  }
  const [dateWise, setDateWise] = useState({
    series: [
      {
        name: 'Date',
        data: []
      }
    ],
    options: chartOptions
  })
  const changeDate = async (date) => {
    try {
      const obj = {
        date:moment(date).format('YYYY-MM-DD')
      }
      const companyarr = []
      const activeuserarr = []
      const response = await dispatch(fetchCompanyDetails(obj)).unwrap()
      if (response?.status?.success) {
        response.data.map((item) => {
          companyarr.push(item?.company?.split(' ')?.[0])
          activeuserarr.push(parseInt(item?.totalUsers))
          return item
        })
        chartOptions1.xaxis.categories = companyarr
        setCompany({
          series: [
            {
              name: 'Users',
              data: activeuserarr
            }
          ],
          options: {
            ...chartOptions1,
            colors: ['#58BDFF'],
            title: {
              ...chartOptions1.title,
              text: ''
            },
            toolbar: {
              show: true,
              offsetY: 0 // Set to false to hide the toolbar/menu icon
            },
            xaxis: {
              ...chartOptions1.xaxis,
              title: {
                ...chartOptions.xaxis.title,
                text: 'Company'
              }
            },
            yaxis: {
              ...chartOptions1.yaxis,
              title: {
                ...chartOptions.yaxis.title,
                text: 'Number of users'
              },
              labels :{
                formatter (val) {
                  return parseInt(val)
                }

              }
            }
          }
        })
        setCategoriesDataForBarChart(companyarr)

      } else {
        toast.error(response?.status?.message)
      }

    } catch (err) {
      toast.error(err.message)
    }

  }
  const handleDateChange = (date) => {
    setChangeAssignDate(date)
    changeDate(date)
  }
  const getCompanyLoginDetails = async (company_id) => {
    try {
      const datewiselogin = []
      const activeuser = []
      const obj = {
        start_date:startDate,
        end_date:endDate,
        company_id
      }
      const resp = await dispatch(getEachCompanyLoginDetails(obj)).unwrap()
      if (resp?.status?.success) {
        resp.data.map((item) => {
          datewiselogin.push(moment(item?.date).format('Do MMM'))
          activeuser.push(item?.totalUsers)
          return item
        })
        chartOptions.xaxis.categories = datewiselogin
        setDateWise({
          series: [
            {
              name: 'Users',
              data: activeuser
            }
          ],
          options: {
            ...chartOptions,
            colors: ['#00b300'],
            title: {
              ...chartOptions.title,
              text: ''
            },
            toolbar: {
              show: false
            },
            xaxis: {
              ...chartOptions.xaxis,
              title: {
                ...chartOptions.xaxis.title,
                text: 'Date'
              }
            },
            yaxis: {
              ...chartOptions.yaxis,
              title: {
                ...chartOptions.yaxis.title,
                text: 'Number of users'
              },
              labels :{
                formatter (val) {
                  return parseInt(val)
                }

              }
            }
          }
        })
        setCategoriesDataForBarChart1(datewiselogin)
      } else {
        toast.error(resp?.status?.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const getAllCompanyList = async () => {
    try {
      const resp = await dispatch(getCompanyList()).unwrap()
      if (resp?.status?.success) {
        let companyList = resp.data
        companyList = companyList.map((item) => {

          return {
            label:item.name,
            value:item.id
          }
        })
        setCompanyListDropdown(companyList)

      }

    } catch (error) {
      toast.error(error.message)
    }
  }

  const changeSelection = (selection = 'this_month') => {
    switch (selection) {
    case 'this_week':
      setStartDate(moment().startOf('week').format('YYYY-MM-DD'))
      setEndDate(moment().endOf('week').format('YYYY-MM-DD'))
      break
    case 'this_month':
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'))
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'))
      break
    case 'last_week':
      setStartDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'))
      setEndDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'))
      break
    case 'last_month':
      setStartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
      setEndDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
      break
    default:
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'))
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'))
      break
    }
  }
  const handleDurationChange = (event) => {
    setDuration(event.target.value)
  }
  async function changeCompanyId (...args) {
    try {
      if (args[1] === 'company_id') {
        setCompanyName(args[0] ? args[0] : '')
      }
    } catch (ex) {
      toast.error(ex.message)
    }
  }
  useEffect(() => {
    getAllCompanyList()
  }, [])
  useEffect(() => {
    if (changeAssignDate) {
      changeDate(changeAssignDate)
    }
  }, [])
  useEffect(() => {
    if (companyName?.value) {
      getCompanyLoginDetails(companyName.value)
    }
  }, [companyName, startDate, endDate])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className='d-flex w-100'style={{display:'flex', flexWrap:'wrap', justifyContent: 'center', marginTop: '15px'}}>
            <Card style={{ marginRight: '25px'}}>
              <CardBody className='pb-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <p className='mb-0'>User Logged In</p>
                  </div>
                  <div className='d-flex col-md-2 justify-content-end pe-1 pt-1' style={{width:'150px'}}>
                    <LocalizationProvider className="datepickerwidth"  dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date"
                        className='datepickerwidth'
                        format="dd/MM/yyyy"
                        inputFormat="dd/MM/yyyy"
                        name="Date"
                        slotProps={{ textField: { size: 'small' } }}
                        value={changeAssignDate}
                        onChange={(event) => handleDateChange(event)}
                        renderInput={(params) => <TextField {...params} error={false} hiddenLabel fullWidth size="small" />}
                      />
                    </LocalizationProvider>

                  </div>
                </div>
                <div className='mt-2'>
                  <ReactApexChart
                    id={'myChart'}
                    options={company.options}
                    series={company.series}
                    type="bar"
                    width={categoriesDataForBarChart.length > 10 ? 1000 : 500}
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody className='pb-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <p className='mb-0'>User Logged in(Company)</p>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='me-2'>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        value={companyName.value}
                        options={companyListDropdown}
                        style={{ minWidth: '160px' }}
                        renderInput={(params) => <TextField {...params} label="Select Company" />}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            changeCompanyId(newValue, 'company_id')
                          } else {
                            changeCompanyId(newValue, '')
                          }
                        }}
                      />
                    </div>
                    <div className='me-1'>
                      <FormControl size='small' sx={{minWidth: 120 }}>
                        {/* <InputLabel id="demo-simple-select-label">This Month</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select-label"
                          value={duration}
                          onChange={handleDurationChange}
                          className='blue_selectBox'
                        >
                          <MenuItem value={'week'} onClick={() => { changeSelection('this_week') }}>This week</MenuItem>
                          <MenuItem value={'month'} onClick={() => { changeSelection('this_month') }}>This month</MenuItem>
                          <MenuItem value={'previous_week'} onClick={() => { changeSelection('last_week') }}>Last week</MenuItem>
                          <MenuItem value={'previous_month'} onClick={() => { changeSelection('last_month') }}>Last month</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className='mt-2'>
                  <ReactApexChart
                    options={dateWise.options}
                    series={dateWise.series}
                    type="bar"
                    width={categoriesDataForBarChart1.length > 10 ? 1000 : 500}
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reports
