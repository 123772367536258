// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS'
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR'
export const GET_FOLDERS = 'GET_FOLDERS'

// Add Folder
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER'
export const ADD_FOLDER_SUCCESS = 'ADD_FOLDER_SUCCESS'
export const ADD_FOLDER_FAIL = 'ADD_FOLDER_FAIL'

// Edit Folder
export const UPDATE_FOLDER = 'UPDATE_FOLDER'
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_FAIL = 'UPDATE_FOLDER_FAIL'

// Delete Folder
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_FAIL = 'DELETE_FOLDER_FAIL'

// Get File
export const GET_FILES = 'GET_FILES'

// Add File
export const ADD_NEW_FILE = 'ADD_NEW_FILE'
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS'
export const ADD_FILE_FAIL = 'ADD_FILE_FAIL'

// Edit File
export const UPDATE_FILE = 'UPDATE_FILE'
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS'
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL'

// Delete File
export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL'
