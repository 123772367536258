// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS'
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR'
export const GET_TODOS = 'GET_TODOS'

// Add Todo
export const ADD_NEW_TODO = 'ADD_NEW_TODO'
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS'
export const ADD_TODO_FAIL = 'ADD_TODO_FAIL'

// Edit Todo
export const UPDATE_TODO = 'UPDATE_TODO'
export const UPDATE_TODO_SUCCESS = 'UPDATE_TODO_SUCCESS'
export const UPDATE_TODO_FAIL = 'UPDATE_TODO_FAIL'

// Delete Todo
export const DELETE_TODO = 'DELETE_TODO'
export const DELETE_TODO_SUCCESS = 'DELETE_TODO_SUCCESS'
export const DELETE_TODO_FAIL = 'DELETE_TODO_FAIL'

// Projects
export const GET_PROJECTS = 'GET_PROJECTS'

// Add Project
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_FAIL = 'ADD_PROJECT_FAIL'
