import React, {useEffect} from 'react'

//import Scss
import './assets/scss/themes.scss'

//imoprt Route
import Route from './Routes'
import { useNavigate } from 'react-router-dom'

// fakeBackend();

function App () {

  const navigate = useNavigate()
  useEffect(() => {
    //refreshToken()
    //console.log(JSON.parse(sessionStorage.getItem("authUser")))
    if (sessionStorage.getItem('authUser') == null) {
      navigate('/login')
    }
  }, [])
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  )
}

export default App
