import React, { useState } from 'react'

const Navdata = () => {
  //state data
  const [isDashboard, setIsDashboard] = useState(true)
  const [isCompaniesList, setIsCompaniesList] = useState(false)
  const [plan, setplan] = useState(false)
  // const [settings, setSettings] = useState(false)
  const [reports, setReports] = useState(false)
  const [, setIscurrentState] = useState('Dashboard')

  function updateIconSidebar (e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      const activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        const id = item.getAttribute('subitems')
        if (document.getElementById(id)) document.getElementById(id).classList.remove('show')
      })
    }
  }

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isDashboard,
      click (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('Dashboard')
        updateIconSidebar(e)
      }
    },
    {
      id: 'companies_list',
      label: 'Companies',
      icon: 'ri-apps-2-line',
      stateVariables: isCompaniesList,
      click (e) {
        e.preventDefault()
        setIsCompaniesList(!isCompaniesList)
        setIscurrentState('CompaniesList')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'active',
          label: 'Active',
          link: '/companies_list/ACT',
          parentId: 'companies_list'
        },
        {
          id: 'inactive',
          label: 'InActive',
          link: '/companies_list/INA',
          parentId: 'companies_list'
        }
      ]
    },
    {
      id: 'plans',
      label: 'Plans',
      icon: 'ri-apps-2-line',
      link: '/plan',
      stateVariables: plan,
      click (e) {
        e.preventDefault()
        setplan(!plan)
        setIscurrentState('Plans')
        updateIconSidebar(e)
      }
    },
    {
      id: 'reports',
      label: 'Reports',
      icon: 'ri-apps-2-line',
      link: '/reports',
      stateVariables: reports,
      click (e) {
        e.preventDefault()
        setReports(!reports)
        setIscurrentState('Reports')
        updateIconSidebar(e)
      }
    }
    // {
    //   id: 'settings',
    //   label: 'Settings',
    //   icon: 'ri-apps-2-line',
    //   link: '/settings',
    //   stateVariables: settings,
    //   click (e) {
    //     e.preventDefault()
    //     setSettings(!settings)
    //     setIscurrentState('Settings')
    //     updateIconSidebar(e)
    //   }
    // }
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata